import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SyncIcon from "@mui/icons-material/Sync";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import CustomChip from "../general/Chip";
import SelectInput from "../select-input";
import CaseActionButtons from "../case-action-buttons";
import ThemeContext from "../../utils/context/theme-context";
import DownloadDealReportsButton from "../download-reports-button";
import { externalDarkTheme, externalLightTheme } from "../../utils/theme";
import { getDealDetails, updateDeal } from "../../apis/deal/base";
import { getDealDebt } from "../../apis/deal/debt";
import { useAPI, useAppSelector } from "../../utils/hooks";
import { ISelectOption, IUpdateDealForm } from "../../interfaces";
import {
  cn,
  getFilledChipColor,
  getLayoutTabs,
  navigateAndScroll,
  trimString,
} from "../../utils/helpers";
import {
  deleteDealCase,
  duplicateDealCase,
  getDealCaseDetails,
  getDealCases,
  updateDealCase,
} from "../../apis/deal/case";
import {
  DEAL_CASE_TYPE,
  PRIMARY_SIDEBAR_NAVIGATION,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON,
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
  DEAL_STATUS,
  DEAL_STATUS_OPTIONS,
} from "../../constants";
import {
  downloadABCInputs,
  downloadDealReport,
  downloadDealReportSet,
  downloadUserDealReport,
} from "../../apis/report/deal";
import {
  setCurrentDealAction,
  setCurrentDealTermDebtAction,
  setCurrentDealTermDebtStatusAction,
  setCurrentDealStatusAction,
  updateSelectedDealOutputPageTabAction,
  updateDealOutputLoadedAction,
  setCasesOfDealAction,
  resetSelectedDealOutputPageTabAction,
  setConfirmModalPropsAction,
  resetConfirmModalPropsAction,
} from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
}

export default function PrimaryDealPagesLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { caseId, dealUuid, caseDealUuid } = useParams();

  const { darkMode } = React.useContext(ThemeContext);

  const dispatch = useDispatch();
  const { contentLoaded } = useAppSelector((s) => s.common);
  const { currentDeal, selectedDealOutputPageTab, casesOfDeal } =
    useAppSelector((s) => s.deal);

  const hideCaseSelector = DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON.some((path) =>
    pathname.includes(path),
  );
  const dealIdToUse = React.useMemo(
    () => (hideCaseSelector ? dealUuid : caseDealUuid),
    [hideCaseSelector, dealUuid, caseDealUuid],
  );

  const hasStickyTabs: boolean = React.useMemo(() => {
    const pagesWithStickyTabs = ["summary", "charts", "splits"];
    const containsTabs = pagesWithStickyTabs.some((page) =>
      pathname.includes(page),
    );
    return containsTabs;
  }, [pathname]);

  const styles = useStyles({ contentLoaded, hasStickyTabs });

  React.useEffect(() => {
    if (dealUuid && dealUuid !== dealIdToUse) {
      dispatch(
        updateSelectedDealOutputPageTabAction({
          ...DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
          dealUuid,
        }),
      );
    }

    if (currentDeal?.uuid !== dealIdToUse) {
      getDealCasesCallAPI(dealUuid).then((res) => {
        res && dispatch(setCasesOfDealAction(res.results));
      });

      getDealDetailsCallAPI(dealIdToUse).then((res) => {
        res && dispatch(setCurrentDealAction(res));
      });
      getDealDebtCallAPI(dealIdToUse).then((res) => {
        res && dispatch(setCurrentDealTermDebtAction(res[0]));
      });
    }

    if (!pathname.includes("/output/")) {
      dispatch(updateDealOutputLoadedAction(false));
    }

    if (pathname.includes("/case/")) {
      getDealCaseDetailsCallAPI(dealUuid, caseId);
    }
  }, [dealUuid, caseId, dealIdToUse]);

  const {
    callAPI: getDealDetailsCallAPI,
    loading: loadingDealDetails,
    errored: getDealDetailsFailed,
  } = useAPI((dealUuid: string) => getDealDetails(dealUuid));

  const { callAPI: updateDealCallAPI } = useAPI(
    (dealUuid: string, form: IUpdateDealForm) => updateDeal(dealUuid, form),
  );

  React.useEffect(() => {
    dispatch(
      setCurrentDealStatusAction({
        errored: getDealDetailsFailed,
        loading: loadingDealDetails,
      }),
    );
  }, [loadingDealDetails, getDealDetailsFailed]);

  const {
    callAPI: getDealDebtCallAPI,
    loading: loadingDealDebt,
    errored: getDealDebtFailed,
  } = useAPI((dealUuid: string) => getDealDebt(dealUuid));

  React.useEffect(() => {
    dispatch(
      setCurrentDealTermDebtStatusAction({
        loading: loadingDealDebt,
        errored: getDealDebtFailed,
      }),
    );
  }, [getDealDebtFailed, loadingDealDebt]);

  const resetConstructionDebtTab = (tabKey: string) => {
    if (selectedDealOutputPageTab["construction-debt"] === tabKey) {
      dispatch(resetSelectedDealOutputPageTabAction("construction-debt"));
    }
  };

  React.useEffect(() => {
    if (!currentDeal?.has_construction_loan) {
      resetConstructionDebtTab("construction-loan");
    }
  }, [currentDeal?.has_construction_loan]);

  React.useEffect(() => {
    if (!currentDeal?.has_investor_bridge_loan) {
      resetConstructionDebtTab("investor-bridge-loan");
    }
  }, [currentDeal?.has_investor_bridge_loan]);

  const { callAPI: getDealCasesCallAPI } = useAPI((dealUuid: string) =>
    getDealCases(dealUuid),
  );

  const { callAPI: getDealCaseDetailsCallAPI, response: caseDetails } = useAPI(
    (dealUuid: string, caseId: string) => getDealCaseDetails(dealUuid, caseId),
    { initialLoading: true },
  );

  const onProjectChipClick = (projectUuid: string) => {
    window.open(`/project/${projectUuid}/general`, "_blank");
  };

  const onCaseChange = async (e: SelectChangeEvent<unknown>) => {
    const caseDealUuid = e.target.value as string;
    const splitPath = pathname.split("/");
    splitPath[4] = caseDealUuid;
    // call api to refresh tabs when in sizing/output page
    if (pathname.includes("/output/") || pathname.includes("/sizing/")) {
      const dealDetails = await getDealDetailsCallAPI(e.target.value);
      const dealTermDebt = await getDealDebtCallAPI(e.target.value);
      const t = pathname.split("/")[5];
      if (dealDetails && dealTermDebt) {
        const links =
          PRIMARY_SIDEBAR_NAVIGATION.find(
            (i) => i.label === "Deals",
          )?.subLinks?.find(
            (i) => i.label === (t === "sizing" ? "Sizing" : "Output"),
          )?.subLinks || [];

        const tabUrls = getLayoutTabs({
          module: "Deals",
          currentDeal: dealDetails,
          currentDealTermDebt: dealTermDebt[0],
          links,
        }).map((t) =>
          t.path
            .replace(":dealUuid", String(dealUuid))
            .replace(":caseDealUuid", String(caseDealUuid)),
        );

        if (t === "output") {
          splitPath.pop();
        }
        if (!tabUrls.includes(splitPath.join("/"))) {
          navigate(tabUrls[0]);
          return;
        }
      }
    }
    navigate(splitPath.join("/"));
  };

  const handleCaseSwitch = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    const selectedCase = casesOfDeal.find(
      (c) => c.uuid === String(event.target.value),
    );
    selectedCase && navigate(`/deal/${dealUuid}/case/${selectedCase.uuid}`);
  };

  const goToCase = () => {
    const caseId = casesOfDeal.find(
      (c) => c.child_deal.uuid === caseDealUuid,
    )?.uuid;

    if (caseId) {
      navigate(`/deal/${dealUuid}/case/${caseId}`);
    }
  };

  const goToOutputPage = (caseDealUuid: string) => {
    navigate(
      `/deal/${dealUuid}/case-deal/${caseDealUuid}/output/partnership/summary`,
    );
  };

  const casesOptions: ISelectOption[] = React.useMemo(() => {
    if (currentDeal && casesOfDeal) {
      const cases = casesOfDeal.map((c) => ({
        label: c.name,
        value: String(c.child_deal.uuid),
      }));
      return [{ label: "Base Case", value: String(dealUuid) }, ...cases];
    }
    return [];
  }, [currentDeal, casesOfDeal]);

  const caseSwitchOptions: ISelectOption[] = React.useMemo(() => {
    return casesOfDeal.length
      ? casesOfDeal.map((c) => ({ label: c.name, value: String(c.uuid) }))
      : [];
  }, [casesOfDeal]);

  const activeCase = React.useMemo(() => {
    const caseIdToUse = caseDealUuid || caseId;
    return casesOfDeal.find((c) => c.uuid === caseIdToUse);
  }, [caseId, casesOfDeal]);

  const handleNavigate = async (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  const chipElements = React.useMemo(() => {
    if (pathname.includes("/case/")) {
      if (activeCase) {
        const elements = [
          DEAL_CASE_TYPE[activeCase.type],
          DEAL_STRUCTURE_TYPE[activeCase.child_deal.structure],
          DEAL_TAX_CREDIT_STRUCTURE_TYPE[
            activeCase.child_deal.tax_credit_structure
          ],
        ].map((l, i) => <Chip key={i} label={l} />);

        if (activeCase.is_synced_with_base_case) {
          elements.push(
            <Tooltip
              key={3}
              title="Any unmodified data in this Case syncs automatically with the Base Case"
            >
              <Chip icon={<SyncIcon />} label="Synced" />
            </Tooltip>,
          );
        }

        if (currentDeal?.status !== "ARCH") {
          elements.push(
            <Chip
              key={4}
              variant="outlined"
              label="View Output"
              onClick={() => goToOutputPage(activeCase.child_deal.uuid)}
              icon={<ArrowForwardIcon color="inherit" fontSize="small" />}
              classes={{ root: "!text-secondary !border-secondary" }}
            />,
          );
        }

        return elements;
      }
    }
    if (currentDeal && (dealUuid === caseDealUuid || !caseDealUuid)) {
      const elements = [
        DEAL_STRUCTURE_TYPE[currentDeal.structure],
        DEAL_TAX_CREDIT_STRUCTURE_TYPE[currentDeal.tax_credit_structure],
      ].map((l, i) => <Chip key={i} label={l} />);

      if (currentDeal.projects.length > 5) {
        elements.push(
          <Chip
            key={4}
            label="Portfolio"
            variant="outlined"
            onClick={() =>
              handleNavigate(
                `/deal/${currentDeal?.uuid}/case-deal/${currentDeal?.uuid}/general`,
                "deal-projects",
              )
            }
          />,
        );
      } else {
        currentDeal.projects.forEach((p, idx) => {
          elements.push(
            <Chip
              key={idx + 5}
              label={p.name}
              variant="outlined"
              icon={<OpenInNewIcon fontSize="small" />}
              onClick={() => {
                onProjectChipClick(p.uuid);
              }}
            />,
          );
        });
      }

      return elements;
    } else {
      const caseDetails = casesOfDeal.find(
        (c) => c.child_deal.uuid === caseDealUuid,
      );

      if (caseDetails) {
        const elements = [
          DEAL_CASE_TYPE[caseDetails.type],
          DEAL_STRUCTURE_TYPE[caseDetails.child_deal.structure],
          DEAL_TAX_CREDIT_STRUCTURE_TYPE[
            caseDetails.child_deal.tax_credit_structure
          ],
        ].map((l, i) => <Chip key={i} label={l} />);

        elements.push(
          <Chip
            key={3}
            variant="outlined"
            label="View Case Details"
            onClick={goToCase}
            icon={<ArrowForwardIcon color="inherit" fontSize="small" />}
            classes={{ root: "!text-secondary !border-secondary" }}
          />,
        );

        return elements;
      }

      return [];
    }
  }, [currentDeal, casesOfDeal]);

  const backButtonDetails = React.useMemo(() => {
    let name = "Deals";
    let path = "/deal-list";

    if (pathname.includes("/case/")) {
      name = "Cases";
      path = `/deal/${dealUuid}/cases`;
    }
    if (pathname.includes("/analysis/")) {
      name = "Analysis";
      path = `/deal/${dealUuid}/analysis`;
    }

    return {
      name,
      path,
    };
  }, [pathname]);

  const selectedTheme = React.useMemo(
    () => (darkMode ? externalDarkTheme : externalLightTheme),
    [darkMode],
  );

  const refetchCasesOfDeal = async () => {
    const dealCases = await getDealCasesCallAPI(dealUuid);
    dealCases && dispatch(setCasesOfDealAction(dealCases.results));
  };

  const handleUpdateDealStatus = async (status: string) => {
    const updatedDeal = await updateDealCallAPI(dealUuid, {
      status,
    });

    if (currentDeal && updatedDeal) {
      const { name, stage, tax_credit_structure, projects, status } =
        updatedDeal;

      dispatch(
        setCurrentDealAction({
          ...currentDeal,
          name,
          tax_credit_structure,
          stage,
          projects,
          status,
        }),
      );
      dispatch(resetConfirmModalPropsAction());
    }
  };

  const handleConfirmDealStatusUpdate = async (status: string) => {
    dispatch(
      setConfirmModalPropsAction({
        open: true,
        title: "Confirm Deal Status Update",
        description: `Are you sure you want to update the status of this Deal and make it 'Draft'?`,
        onConfirm: () => handleUpdateDealStatus(status),
      }),
    );
  };

  return (
    <Layout title={currentDeal?.name}>
      <>
        {currentDeal?.status ===
          DEAL_STATUS_OPTIONS.find((o) => o.value === "ARCH")?.value && (
          <Alert severity="warning">
            {" "}
            This Deal has been archived.{" "}
            <span
              className="cursor-pointer font-semibold"
              onClick={() => handleConfirmDealStatusUpdate("DRFT")}
            >
              Click here
            </span>{" "}
            to unarchive.{" "}
          </Alert>
        )}
        {currentDeal && (
          <Box className={cn("sticky top-68 p-4 pb-0 z-6")}>
            <Link
              to={backButtonDetails.path}
              className={cn(
                `flex items-center text-${selectedTheme.color.text} !no-underline w-fit hover:text-secondary`,
              )}
            >
              <ArrowBackIcon />
              <Typography>{`Back to ${backButtonDetails.name} List`}</Typography>
            </Link>

            <Box className={cn("flex justify-between items-end mb-2")}>
              <Box className={cn("flex flex-col w-full")}>
                <Box className={cn("mt-2 flex items-center justify-start")}>
                  <Typography variant="h5" className={cn("text-nowrap !mr-6")}>
                    {trimString(currentDeal?.name, 45)}
                  </Typography>

                  <CustomChip
                    className="!mb-1"
                    label={DEAL_STATUS[currentDeal.status]}
                    color={getFilledChipColor(currentDeal.status).color}
                    filledBackgroundColor={
                      getFilledChipColor(currentDeal.status).backgroundColor
                    }
                    variant="filled"
                  />
                </Box>

                <Box className={cn("flex flex-wrap gap-2 my-2")}>
                  {!hideCaseSelector && (
                    <SelectInput
                      label="Case"
                      items={casesOptions}
                      selected={caseDealUuid ?? ""}
                      onChange={onCaseChange}
                      className={cn("w-64 h-9 mb-[-12px]")}
                      fullWidth={false}
                    />
                  )}
                  {pathname.includes("/case/") && caseDetails && (
                    <SelectInput
                      variant="outlined"
                      label="Case"
                      onChange={handleCaseSwitch}
                      selected={String(caseId)}
                      items={caseSwitchOptions}
                      className={cn("w-64 h-9 mb-[-12px]")}
                      fullWidth={false}
                    />
                  )}
                  {chipElements}
                </Box>
              </Box>
              {pathname.includes("/output/") && (
                <DownloadDealReportsButton
                  downloadABCInputs={downloadABCInputs}
                  downloadDealReport={downloadDealReport}
                  downloadDealReportSet={downloadDealReportSet}
                  downloadUserDealReport={downloadUserDealReport}
                />
              )}

              {pathname.includes("/case/") && caseDetails && (
                <CaseActionButtons
                  deleteDealCase={deleteDealCase}
                  duplicateDealCase={duplicateDealCase}
                  getDealCaseDetails={getDealCaseDetails}
                  updateDealCase={updateDealCase}
                  refetchCasesOfDeal={refetchCasesOfDeal}
                />
              )}
            </Box>
            <Divider />
          </Box>
        )}

        <Box className={styles.classes.children}>{children}</Box>
      </>
    </Layout>
  );
}
