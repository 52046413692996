import { AxiosHelper } from "../../utils/helpers";
import { SOLVER_API_ROUTES } from "../../constants";

import {
  IAddSolverForm,
  ISolver,
  ISolverResult,
  ISolverStatus,
} from "../../interfaces/deal/solvers.interface";
import { ServerPaginatedResponse } from "../../interfaces";

export const getSolvers = async () => {
  const url = SOLVER_API_ROUTES.base.get;
  return await AxiosHelper.get<ServerPaginatedResponse<ISolver[]>>(url);
};

export const addSolver = async (form: IAddSolverForm) => {
  const url = SOLVER_API_ROUTES.base.create;
  return await AxiosHelper.post<IAddSolverForm, ISolver>(url, form);
};

export const getSolverDetails = async (uuid: string) => {
  const url = SOLVER_API_ROUTES.base.getDetails.replace(":uuid", uuid);
  return await AxiosHelper.get<ISolver>(url);
};

export const getSolverResults = async (uuid: string) => {
  const url = SOLVER_API_ROUTES.base.getResults.replace(":uuid", uuid);
  return await AxiosHelper.get<ISolverResult>(url);
};

export const getSolverStatus = async (uuid: string) => {
  const url = SOLVER_API_ROUTES.base.getStatus.replace(":uuid", uuid);
  return await AxiosHelper.get<ISolverStatus>(url);
};
