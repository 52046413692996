import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { CAPITAL_ACCOUNTS_REPORT_TABLE_COLUMNS } from "../../../../../constants";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  getDealReportingUrl,
  generateCapitalAccountsReportTableRows,
} from "../../../../../utils/helpers";
import {
  IReport,
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
  ) => Promise<IReport>;
}

export default function DealOutputSponsorEquityCapitalAccountsView({
  getDealReporting,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealUuid}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealUuid}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (dealIdToUse) {
      getDealReportingCallAPI(dealIdToUse, buyout).then((response) => {
        if (response?.["pship/capital"]) {
          setReport(response);
        }
      });
    }
  }, [dealIdToUse, buyout]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string, term: ReportTerm) =>
      getDealReporting(dealUuid, "sponsorequity", "capitalaccounts", term),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [loadingReport, getReportFailed]);

  const cashTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateCapitalAccountsReportTableRows(report) : [];
  }, [report]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadUserDealReport(
        dealIdToUse,
        "sponsorequity",
        "capitalaccounts",
        buyout,
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            String(dealIdToUse),
            "sponsorequity",
            "capitalaccounts",
            buyout,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        showBuyout={report?.has_investor_buyout}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showSizingRedirectionButton
      />
      <ReportTable
        columns={CAPITAL_ACCOUNTS_REPORT_TABLE_COLUMNS}
        rows={cashTableRows}
        boldTotal
        stickyTotal
        bigHeader
      />
    </ViewWrapper>
  );
}
