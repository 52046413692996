import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import Logs from "../../../../../../components/logs";
import LogsWrapper from "../../../../../../components/logs-wrapper";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "../../common-styles";
import Button from "../../../../../../components/button";
import PopoverMenu from "../../../../../../components/popover-menu";
import ViewWrapper from "../../../../../../components/view-wrapper";
import LogsButton from "../../../../../../components/logs-button";
import ProjectMerchantRevenueFormModal from "../../../../../../components/project-merchant-revenue-form-modal";
import StickyTableCell from "../../../../../../components/sticky-table-cell";
import ConditionalProtect from "../../../../../../components/conditional-protect";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../../../../utils/hooks";
import {
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../../../../utils/redux/slices";
import {
  PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
  PROJECT_MERCHANT_REVENUE_TYPE,
} from "../../../../../../constants";
import {
  cn,
  getTableColumnAccordingToStatus,
  numberToUSD,
  replacePagePathForPersonalAccount,
  sortArrayOfObjects,
} from "../../../../../../utils/helpers";
import {
  ITableSort,
  ITableColumn,
  IProjectTiming,
  IProjectMerchantRevenue,
  ServerPaginatedResponse,
  IProjectMerchantRevenueForm,
  IProjectMerchantRevenueFormErrors,
  ISelectOption,
  IGetCurvesParams,
  IOrganizationCurve,
  ILogsConfiguration,
} from "../../../../../../interfaces";

const MERCHANT_REVENUE_TABLE_COLUMNS: ITableColumn[] = [
  { id: "type", label: "Type", minWidth: 80, align: "left" },
  { id: "name", label: "Name", minWidth: 80, align: "left" },
  { id: "price", label: "Price", minWidth: 80, align: "left" },
  { id: "price_curve", label: "Price Curve", minWidth: 80, align: "left" },
  {
    id: "escalator",
    label: "Escalator",
    minWidth: 80,
    align: "left",
  },
  {
    id: "base_year",
    label: "Escalator Base Year",
    minWidth: 80,
    align: "left",
  },
  {
    id: "price_haircut",
    label: "Price Haircut / (Adder)",
    minWidth: 80,
    align: "left",
  },
  {
    id: "cash_basis_lag",
    label: "Cash Lag (Months)",
    minWidth: 80,
    align: "left",
  },
];

interface IProps {
  getProjectTiming: (projectUuid: string) => Promise<IProjectTiming[]>;
  deleteProjectMerchantRevenueContract: (
    projectUuid: string,
    revenueContractId: number,
  ) => Promise<boolean>;

  getProjectMerchantRevenues: (
    projectUuid: string,
  ) => Promise<ServerPaginatedResponse<IProjectMerchantRevenue[]>>;
  updateMerchantRevenue: (
    projectUuid: string,
    revenueId: number,
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue>;
  addMerchantRevenue: (
    projectUuid: string,
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function ProjectMerchantRevenueContractsView({
  getProjectTiming,
  deleteProjectMerchantRevenueContract,
  getProjectMerchantRevenues,
  updateMerchantRevenue,
  addMerchantRevenue,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { organization } = useOrganization();

  const navigate = useNavigate();
  const { projectUuid } = useParams();

  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();

  const [merchantRevenues, setMerchantRevenues] = React.useState<
    IProjectMerchantRevenue[]
  >([]);
  const [selectedMerchantRevenueToEdit, setSelectedMerchantRevenueToEdit] =
    React.useState<number>();
  const [updateMerchantRevenueModalOpen, setUpdateMerchantRevenueModalOpen] =
    React.useState<boolean>(false);
  const [addMerchantRevenueModalOpen, setAddMerchantRevenueModalOpen] =
    React.useState<boolean>(false);
  const [merchantRevenueForm, setMerchantRevenueForm] =
    React.useState<IProjectMerchantRevenueForm>(
      PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
    );
  const [sortMerchantRevenueTable, setSortMerchantRevenueTable] =
    React.useState<ITableSort>({
      orderBy: "",
      order: "asc",
    });

  React.useEffect(() => {
    getProjectMerchantRevenuesCallAPI(projectUuid).then((response) => {
      response && setMerchantRevenues(response.results);
    });
    getProjectTimingCallAPI(projectUuid).then((response) => {
      response && setProjectTiming(response[0]);
    });
  }, [projectUuid]);

  const {
    callAPI: getProjectMerchantRevenuesCallAPI,
    errored: getMerchantRevenuesFailed,
    loading: loadingMerchantRevenues,
  } = useAPI((projectUuid: string) => getProjectMerchantRevenues(projectUuid), {
    initialLoading: true,
  });

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectUuid: string) =>
    getProjectTiming(projectUuid),
  );

  const {
    callAPI: updateMerchantRevenueCallAPI,
    fieldErrors: updateMerchantRevenueFormErrors,
    setFieldErrors: setUpdateMerchantRevenueFormErrors,
    loading: updateMerchantRevenueLoading,
  } = useAPI<IProjectMerchantRevenue, IProjectMerchantRevenueFormErrors>(
    (
      projectUuid: string,
      merchantRevenueId: number,
      form: IProjectMerchantRevenueForm,
    ) => updateMerchantRevenue(projectUuid, merchantRevenueId, form),
  );

  const {
    callAPI: addMerchantRevenueCallAPI,
    fieldErrors: addMerchantRevenueFormErrors,
    setFieldErrors: setAddMerchantRevenueFormErrors,
    loading: addMerchantRevenueLoading,
  } = useAPI<IProjectMerchantRevenue, IProjectMerchantRevenueFormErrors>(
    (projectUuid: string, form: IProjectMerchantRevenueForm) =>
      addMerchantRevenue(projectUuid, form),
  );

  const { callAPI: deleteProjectMerchantRevenueContractCallAPI } = useAPI(
    (projectUuid: string, revenueId: number) =>
      deleteProjectMerchantRevenueContract(projectUuid, revenueId),
    { setConfirmModalLoading: true },
  );

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const handleUpdateMerchantRevenue = async (
    form: IProjectMerchantRevenueForm,
  ) => {
    const merchantRevenue = await updateMerchantRevenueCallAPI(
      projectUuid,
      Number(selectedMerchantRevenueToEdit),
      form,
    );
    if (merchantRevenue) {
      const updatedMerchantRevenues = merchantRevenues.map((d) => {
        if (d.id === merchantRevenue.id) {
          return merchantRevenue;
        }
        return d;
      });

      setMerchantRevenues(updatedMerchantRevenues);
    }

    return merchantRevenue;
  };

  const handleAddMerchantRevenue = async (
    form: IProjectMerchantRevenueForm,
  ) => {
    const merchantRevenue = await addMerchantRevenueCallAPI(projectUuid, form);

    if (merchantRevenue) {
      getProjectMerchantRevenuesCallAPI(String(projectUuid)).then(
        (response) => {
          response && setMerchantRevenues(response.results);
        },
      );
    }

    return merchantRevenue;
  };

  const sortedMerchantRevenueRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        merchantRevenues,
        sortMerchantRevenueTable?.orderBy,
        sortMerchantRevenueTable?.order,
      ),
    [merchantRevenues, sortMerchantRevenueTable],
  );

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: ["PC", "ELCC"] }).catch(
      () => null,
    );
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));
  };

  const handleOpenAddMerchantRevenueContractModal = async () => {
    await handleGetCurves();
    setMerchantRevenueForm((prevState) => ({
      ...prevState,
      base_year: getDefaultBaseYear(projectTiming?.cod),
      revenue_curve: new Array(projectTiming?.date_schedule.length).fill(0),
    }));
    setAddMerchantRevenueModalOpen(true);
  };

  const handleOpenEditMerchantRevenueModal = async (id: number) => {
    const merchantRevenue = merchantRevenues.find((d) => d.id === id);

    if (merchantRevenue) {
      await handleGetCurves();
      const { created, created_by, id, modified, project, ...formData } =
        merchantRevenue;

      let { base_year } = merchantRevenue;

      if (!base_year) {
        base_year = getDefaultBaseYear(projectTiming?.cod) || 0;
      }

      setSelectedMerchantRevenueToEdit(id);
      setMerchantRevenueForm({
        ...formData,
        base_year,
      });
      setUpdateMerchantRevenueModalOpen(true);
    }
  };

  const handleCloseUpdateMerchantRevenueModal = () => {
    setSelectedMerchantRevenueToEdit(undefined);
    setUpdateMerchantRevenueModalOpen(false);
  };

  const handleCloseAddMerchantRevenueModal = () => {
    setAddMerchantRevenueModalOpen(false);
  };

  const sortMerchantRevenueRows = (orderBy: string) => {
    if (orderBy === sortMerchantRevenueTable.orderBy) {
      setSortMerchantRevenueTable({
        orderBy,
        order: sortMerchantRevenueTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortMerchantRevenueTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const gotToMerchantRevenue = (id: number) => {
    let pathToUse = `/project/${projectUuid}/pro-forma/revenue/merchant/${id}`;

    if (!organization) {
      pathToUse = replacePagePathForPersonalAccount(
        pathToUse,
        "project",
      ) as string;
    }
    if (ctrlPressed) {
      window.open(pathToUse, "_blank");
      return;
    }
    navigate(pathToUse);
  };

  const handleOnDeleteMerchantRevenueClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Merchant Revenue",
        description: "Are you sure you want to delete this revenue?",
        onConfirm: () => handleDeleteMerchantRevenueContract(id),
      }),
    );
  };

  const handleDeleteMerchantRevenueContract = async (id: number) => {
    const response = await deleteProjectMerchantRevenueContractCallAPI(
      projectUuid,
      id,
    );

    if (response) {
      setMerchantRevenues(merchantRevenues.filter((d) => d.id !== id));
    }
  };

  const priceCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves
      .filter((c) => c.curve_type === "PC")
      .map((c) => ({
        label: c.name,
        value: c.uuid,
      }));
  }, [currentOrgCurves]);

  const elccCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves
      .filter((c) => c.curve_type === "ELCC")
      .map((c) => ({
        label: c.name,
        value: c.uuid,
      }));
  }, [currentOrgCurves]);

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const projectLogConfiguration: ILogsConfiguration = {
    id: "",
    parentId: String(projectUuid),
    type: "projectmerchantrevenue",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectLogConfiguration.type,
      projectLogConfiguration.id,
      projectLogConfiguration.parentId,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingMerchantRevenues}
        error={getMerchantRevenuesFailed}
      >
        <Box className={styles.classes.topSection}>
          <Typography variant="h6">Merchant Revenues</Typography>

          <Box className={cn("flex gap-2")}>
            <LogsButton onClick={handleOnOpenLogs} />

            <ConditionalProtect type="project">
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                btnType="primary"
                label="Add Merchant Revenue"
                onClick={handleOpenAddMerchantRevenueContractModal}
              />
            </ConditionalProtect>
          </Box>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer classes={{ root: styles.classes.tableContainer }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              data-pw="merchant-revenue-table"
            >
              <TableHead className={styles.classes.header}>
                <TableRow>
                  {getTableColumnAccordingToStatus(
                    MERCHANT_REVENUE_TABLE_COLUMNS,
                    currentProject?.status as string,
                  ).map((column, idx) => {
                    if (column.id === "action") {
                      return (
                        <StickyTableCell
                          key={idx}
                          direction="right"
                          fixedColumnWidth={80}
                          align="center"
                          highZIndex
                        >
                          {column.label}
                        </StickyTableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={idx}
                        align={column.align as "left"}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={
                            sortMerchantRevenueTable.orderBy === column.id
                          }
                          direction={
                            sortMerchantRevenueTable.orderBy === column.id
                              ? sortMerchantRevenueTable.order
                              : "asc"
                          }
                          onClick={() => sortMerchantRevenueRows(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMerchantRevenueRows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        getTableColumnAccordingToStatus(
                          MERCHANT_REVENUE_TABLE_COLUMNS,
                          currentProject?.status as string,
                        ).length
                      }
                    >
                      There is no merchant revenue in place currently, please
                      add one.
                    </TableCell>
                  </TableRow>
                ) : null}
                {sortedMerchantRevenueRows.map((mRevenue, idx) => {
                  return (
                    <TableRow
                      hover
                      key={idx}
                      tabIndex={-1}
                      className={styles.classes.dataRow}
                      onClick={() => gotToMerchantRevenue(mRevenue.id)}
                      data-pw={`project-merchant-revenue-${idx + 1}`}
                    >
                      <TableCell align="left">
                        {mRevenue.type === "CAPACITY" &&
                        currentProject?.energy_type === "BAST"
                          ? "Merchant Capacity"
                          : PROJECT_MERCHANT_REVENUE_TYPE[mRevenue.type]}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.name !== null ? mRevenue.name : ""}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.price_curve != null ||
                        mRevenue.type === "OTHER" ||
                        mRevenue.price_input_type === "OC"
                          ? "N/A"
                          : mRevenue.price !== null
                            ? numberToUSD.format(mRevenue.price) +
                              (mRevenue.type === "CAPACITY"
                                ? " / kW-Month"
                                : " / MWh")
                            : null}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.price_curve != null
                          ? "Schedule"
                          : mRevenue.price_input_type === "OC"
                            ? "Org Curve"
                            : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.type === "OTHER"
                          ? "N/A"
                          : mRevenue.escalator + "%"}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.type === "OTHER" ? "N/A" : mRevenue.base_year}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.price_haircut !== undefined &&
                        mRevenue.price_haircut !== null
                          ? mRevenue.price_haircut + "%"
                          : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {mRevenue.cash_basis_lag !== null
                          ? mRevenue.cash_basis_lag
                          : "N/A"}
                      </TableCell>
                      <ConditionalProtect type="project">
                        <StickyTableCell
                          direction="right"
                          fixedColumnWidth={80}
                          align="center"
                        >
                          <PopoverMenu
                            uniqueId={idx}
                            canOpenUpgrade
                            items={[
                              {
                                label: "Edit",
                                dataPw: `edit-merchant-revenue-${idx + 1}`,
                                onClick: () =>
                                  handleOpenEditMerchantRevenueModal(
                                    mRevenue.id,
                                  ),
                              },
                              {
                                label: "Delete",
                                dataPw: `delete-merchant-revenue-${idx + 1}`,
                                onClick: () =>
                                  handleOnDeleteMerchantRevenueClick(
                                    mRevenue.id,
                                  ),
                              },
                            ]}
                          />
                        </StickyTableCell>
                      </ConditionalProtect>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ViewWrapper>

      <ProjectMerchantRevenueFormModal
        headerLabel="Edit Merchant Revenue"
        open={updateMerchantRevenueModalOpen}
        loading={updateMerchantRevenueLoading}
        formErrors={updateMerchantRevenueFormErrors}
        setFormErrors={setUpdateMerchantRevenueFormErrors}
        form={merchantRevenueForm}
        dateSchedule={projectTiming?.date_schedule || []}
        setForm={setMerchantRevenueForm}
        onClose={handleCloseUpdateMerchantRevenueModal}
        onConfirm={handleUpdateMerchantRevenue}
        priceCurves={priceCurvesOptions}
        elccCurves={elccCurvesOptions}
      />

      <ProjectMerchantRevenueFormModal
        headerLabel="Add Merchant Revenue"
        open={addMerchantRevenueModalOpen}
        loading={addMerchantRevenueLoading}
        formErrors={addMerchantRevenueFormErrors}
        setFormErrors={setAddMerchantRevenueFormErrors}
        form={merchantRevenueForm}
        dateSchedule={projectTiming?.date_schedule || []}
        setForm={setMerchantRevenueForm}
        onClose={handleCloseAddMerchantRevenueModal}
        onConfirm={handleAddMerchantRevenue}
        priceCurves={priceCurvesOptions}
        elccCurves={elccCurvesOptions}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
