import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import CustomChip from "../general/Chip";
import DownloadDealReportsButton from "../download-reports-button";
import { getDealDetails } from "../../apis/deal/base";
import { getDealDebt } from "../../apis/deal/debt";
import { IPersonalAccountNavBackButtonId } from "../../interfaces";
import { useAPI, useAppSelector, useLocalStorage } from "../../utils/hooks";
import {
  cn,
  getFilledChipColor,
  navigateAndScroll,
  trimString,
} from "../../utils/helpers";
import {
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
  DEAL_STATUS,
} from "../../constants";
import {
  downloadABCInputs,
  downloadDealReport,
  downloadDealReportSet,
  downloadUserDealReport,
} from "../../apis/report/deal";
import {
  setCurrentDealAction,
  setCurrentDealStatusAction,
  setCurrentDealTermDebtAction,
  updateDealOutputLoadedAction,
  setCurrentDealTermDebtStatusAction,
  updateSelectedDealOutputPageTabAction,
  resetSelectedDealOutputPageTabAction,
} from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
}

export default function PersonalAccountDealPagesLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dealUuid } = useParams();

  const dispatch = useDispatch();
  const { contentLoaded } = useAppSelector((s) => s.common);
  const { currentDeal, selectedDealOutputPageTab } = useAppSelector(
    (s) => s.deal,
  );

  const [personalAccountNavBackButtonIds, setPersonalAccountNavBackButtonIds] =
    useLocalStorage<IPersonalAccountNavBackButtonId[]>(
      "personal-account-nav-back-btn",
      [],
    );

  const hasStickyTabs: boolean = React.useMemo(() => {
    const pagesWithStickyTabs = ["summary", "charts", "splits"];
    const containsTabs = pagesWithStickyTabs.some((page) =>
      pathname.includes(page),
    );
    return containsTabs;
  }, [pathname]);

  const styles = useStyles({ contentLoaded, hasStickyTabs });

  React.useEffect(() => {
    if (dealUuid && dealUuid !== selectedDealOutputPageTab.dealUuid) {
      dispatch(
        updateSelectedDealOutputPageTabAction({
          ...DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
          dealUuid,
        }),
      );
    }

    if (currentDeal?.uuid !== dealUuid) {
      getDealDetailsCallAPI(dealUuid).then((res) => {
        res && dispatch(setCurrentDealAction(res));
      });
      getDealDebtCallAPI(dealUuid).then((res) => {
        res && dispatch(setCurrentDealTermDebtAction(res[0]));
      });
    }

    if (!pathname.includes("/output/")) {
      dispatch(updateDealOutputLoadedAction(false));
    }
  }, [dealUuid]);

  const {
    callAPI: getDealDetailsCallAPI,
    loading: loadingDealDetails,
    errored: getDealDetailsFailed,
  } = useAPI((dealUuid: string) => getDealDetails(dealUuid));

  React.useEffect(() => {
    dispatch(
      setCurrentDealStatusAction({
        errored: getDealDetailsFailed,
        loading: loadingDealDetails,
      }),
    );
  }, [loadingDealDetails, getDealDetailsFailed]);

  const {
    callAPI: getDealDebtCallAPI,
    loading: loadingDealDebt,
    errored: getDealDebtFailed,
  } = useAPI((dealUuid: string) => getDealDebt(dealUuid));

  React.useEffect(() => {
    dispatch(
      setCurrentDealTermDebtStatusAction({
        loading: loadingDealDebt,
        errored: getDealDebtFailed,
      }),
    );
  }, [getDealDebtFailed, loadingDealDebt]);

  const resetConstructionDebtTab = (tabKey: string) => {
    if (selectedDealOutputPageTab["construction-debt"] === tabKey) {
      dispatch(resetSelectedDealOutputPageTabAction("construction-debt"));
    }
  };

  React.useEffect(() => {
    if (!currentDeal?.has_construction_loan) {
      resetConstructionDebtTab("construction-loan");
    }
  }, [currentDeal?.has_construction_loan]);

  React.useEffect(() => {
    if (!currentDeal?.has_investor_bridge_loan) {
      resetConstructionDebtTab("investor-bridge-loan");
    }
  }, [currentDeal?.has_investor_bridge_loan]);

  const onProjectChipClick = (projectUuid: string) => {
    const collaborationObj = personalAccountNavBackButtonIds.find((d) =>
      d.deals.find((deal) => deal.uuid === dealUuid),
    );

    if (collaborationObj) {
      const idsInDealObj = collaborationObj.deals.find(
        (d) => d.uuid === dealUuid,
      );

      if (idsInDealObj && !idsInDealObj.projectUuids.includes(projectUuid)) {
        idsInDealObj.projectUuids.push(projectUuid);

        const filteredCollabIds = personalAccountNavBackButtonIds.filter(
          (ids) => ids.collaborationId !== collaborationObj.collaborationId,
        );

        const filteredDealIds = collaborationObj.deals.filter(
          (deal) => deal.uuid !== dealUuid,
        );

        setPersonalAccountNavBackButtonIds([
          ...filteredCollabIds,
          {
            collaborationId: collaborationObj.collaborationId,
            deals: [...filteredDealIds, idsInDealObj],
          },
        ]);
      }
    }

    window.open(`/personal/project/${projectUuid}/general`, "_blank");
  };

  const handleNavigate = async (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  const chipElements = React.useMemo(() => {
    if (currentDeal) {
      const elements = [
        DEAL_STRUCTURE_TYPE[currentDeal.structure],
        DEAL_TAX_CREDIT_STRUCTURE_TYPE[currentDeal.tax_credit_structure],
      ].map((l, i) => <Chip key={i} label={l} />);

      if (currentDeal.projects.length > 5) {
        elements.push(
          <Chip
            key={4}
            label="Portfolio"
            variant="outlined"
            onClick={() =>
              handleNavigate(
                `/personal/deal/${dealUuid}/general`,
                "deal-projects",
              )
            }
          />,
        );
      } else {
        currentDeal.projects.forEach((p, idx) => {
          elements.push(
            <Chip
              key={idx + 5}
              label={p.name}
              variant="outlined"
              icon={<OpenInNewIcon fontSize="small" />}
              onClick={() => {
                onProjectChipClick(p.uuid);
              }}
            />,
          );
        });
      }

      return elements;
    }
    return [];
  }, [currentDeal]);

  return (
    <Layout title={currentDeal?.name}>
      <>
        {currentDeal && (
          <Box className={cn("sticky top-68 p-4 pb-0 z-6")}>
            <Box className={cn("flex justify-between items-end mb-2")}>
              <Box className={cn("flex flex-col w-full")}>
                <Box className={cn("mt-2 flex items-center justify-start")}>
                  <Typography variant="h5" className={cn("text-nowrap !mr-6")}>
                    {trimString(currentDeal?.name, 45)}
                  </Typography>

                  <CustomChip
                    className="!mb-1"
                    label={DEAL_STATUS[currentDeal.status]}
                    color={getFilledChipColor(currentDeal.status).color}
                    filledBackgroundColor={
                      getFilledChipColor(currentDeal.status).backgroundColor
                    }
                    variant="filled"
                  />
                </Box>

                <Box className={cn("flex flex-wrap gap-2 my-2")}>
                  {chipElements}
                </Box>
              </Box>
              {pathname.includes("/output/") && (
                <DownloadDealReportsButton
                  downloadABCInputs={downloadABCInputs}
                  downloadDealReport={downloadDealReport}
                  downloadDealReportSet={downloadDealReportSet}
                  downloadUserDealReport={downloadUserDealReport}
                />
              )}
            </Box>
            <Divider />
          </Box>
        )}

        <Box className={styles.classes.children}>{children}</Box>
      </>
    </Layout>
  );
}
