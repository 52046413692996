import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import useStyles from "./styles";
import UpsellView from "../../upsell-view";
import Button from "../../../components/button";
import Chip from "../../../components/general/Chip";
import Searchbar from "../../../components/search-bar";
import ViewWrapper from "../../../components/view-wrapper";
import ConditionalProtect from "../../../components/conditional-protect";
import CollaborationFormModal from "../../../components/collaboration-form-modal";
import { useAPI, useAppSelector, useSearchBar } from "../../../utils/hooks";
import { CREATION_DEAL_STATUS, DEAL_STATUS } from "../../../constants";
import {
  cn,
  getFilledChipColor,
  sortArrayOfObjects,
} from "../../../utils/helpers";
import {
  ISelectOption,
  ITableSort,
  ServerPaginatedResponse,
  ICollaboration,
  ICollaborationForm,
  ICollaborationFormErrors,
  IGetDealsParams,
  IDeal,
} from "../../../interfaces";

const columns = [
  { id: "name", label: "Collaboration Name", minWidth: 170, align: "left" },
  { id: "deal_shared", label: "Deal Shared", minWidth: 100, align: "left" },
  { id: "cases", label: "Cases", minWidth: 50, align: "left" },
  {
    id: "counterparties",
    label: "Counterparties",
    minWidth: 50,
    align: "left",
  },
  { id: "status", label: "Status", minWidth: 50, align: "right" },
];

interface IProps {
  getCollaborations: () => Promise<ServerPaginatedResponse<ICollaboration[]>>;
  createCollaboration: (form: ICollaborationForm) => Promise<ICollaboration>;
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
}

export default function CollaborationListView({
  getCollaborations,
  createCollaboration,
  getDeals,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();
  const { searchString, onSearchStringChange, handleClearSearchString } =
    useSearchBar();

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [addCollaborationModalOpen, setAddCollaborationModalOpen] =
    React.useState<boolean>(false);
  const [collaborations, setCollaborations] = React.useState<ICollaboration[]>(
    [],
  );
  const [form, setForm] = React.useState<ICollaborationForm>({
    name: null,
    deal: null,
    description: null,
    status: "ACT",
  });
  const [dealOptions, setDealOptions] = React.useState<{
    [key in CREATION_DEAL_STATUS]: ISelectOption[];
  }>({
    Active: [],
    Draft: [],
  });

  const { currentOrg } = useAppSelector((s) => s.org);

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const {
    callAPI: getCollaborationsCallAPI,
    loading: collaborationsLoading,
    errored: collaborationsErrored,
  } = useAPI(getCollaborations, {
    initialLoading: currentOrg?.is_feature_external_sharing_enabled,
  });

  React.useEffect(() => {
    if (currentOrg?.is_feature_external_sharing_enabled) {
      handleGetCollaborations();
    }
  }, [currentOrg]);

  const handleGetCollaborations = () => {
    getCollaborationsCallAPI().then((res) => {
      res && setCollaborations(res.results);
    });
  };

  const handleGetDeals = async () => {
    const deals = await getDeals({}).catch(() => null);
    if (deals) {
      const categorizedDeals: {
        [key in CREATION_DEAL_STATUS]: ISelectOption[];
      } = {
        Active: [],
        Draft: [],
      };

      deals?.results?.forEach((d: IDeal) => {
        if (d?.status !== "ARCH") {
          categorizedDeals[DEAL_STATUS[d?.status]]?.push({
            label: d.name,
            value: d.uuid,
          });
        }
      });

      setDealOptions(categorizedDeals);
    }
  };

  const handleOpenAddCollaborationModal = async () => {
    await handleGetDeals();
    setAddCollaborationModalOpen(true);
  };

  const handleCloseAddCollaborationModal = () => {
    setAddCollaborationModalOpen(false);
  };

  const {
    callAPI: createCollaborationCallAPI,
    loading: createCollaborationLoading,
    fieldErrors: createCollaborationFieldErrors,
    setFieldErrors: setCreateCollaborationFieldErrors,
  } = useAPI<ICollaboration, ICollaborationFormErrors>(createCollaboration);

  const handleAddCollaboration = async (form: ICollaborationForm) => {
    const collaboration = await createCollaborationCallAPI(form);
    if (collaboration) {
      gotoCollaborationGeneral(collaboration.uuid);
    }
    return collaboration;
  };

  const gotoCollaborationGeneral = (collaborationUuid: string) => {
    navigate(`/collaboration/${collaborationUuid}/general`);
  };

  const filteredRows = React.useMemo(() => {
    return collaborations.filter((c) =>
      c.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [collaborations, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!currentOrg?.is_feature_external_sharing_enabled) {
    return <UpsellView featureImageSrc="./upsell-collaboration.png" />;
  }

  return (
    <>
      <ViewWrapper
        loading={collaborationsLoading}
        error={collaborationsErrored}
      >
        {visibleRows.length === 0 ? (
          <Box className={styles.classes.emptyContainer}>
            <Box className={cn("flex flex-col items-center")}>
              <ConditionalProtect type="externalshare">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add Deal Share"
                  onClick={handleOpenAddCollaborationModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>
              <Typography
                variant="body1"
                className={styles.classes.createInfo}
                data-pw="empty-project-valuation-summary-message"
              >
                Deal Share does not exist, click on Add Deal Share to add one.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className="m-4">
            <Box className="flex justify-between items-center mb-4">
              <Box>
                <Searchbar
                  searchString={searchString}
                  onSearchStringChange={onSearchStringChange}
                  handleClearSearchString={handleClearSearchString}
                />
              </Box>
              <ConditionalProtect type="externalshare">
                <Button
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Deal Share"
                  onClick={handleOpenAddCollaborationModal}
                />
              </ConditionalProtect>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead classes={{ root: styles.classes.tableHeader }}>
                    <TableRow>
                      {columns.map((column, idx) => (
                        <TableCell
                          key={idx}
                          align={column.align as "left" | "right"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={sortTable.orderBy === column.id}
                            direction={
                              sortTable.orderBy === column.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((row, idx) => (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        onClick={() => gotoCollaborationGeneral(row.uuid)}
                        data-pw={`row-${idx + 1}`}
                        className="cursor-pointer"
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.deal_name}</TableCell>
                        <TableCell>{row.case_count}</TableCell>
                        <TableCell>{row.counterparty_count}</TableCell>
                        <TableCell align="right">
                          <Chip
                            label={
                              CREATION_DEAL_STATUS[
                                row.status as keyof typeof CREATION_DEAL_STATUS
                              ]
                            }
                            color={getFilledChipColor(row.status).color}
                            filledBackgroundColor={
                              getFilledChipColor(row.status).backgroundColor
                            }
                            variant="filled"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                className={cn("bg-table-gray")}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </ViewWrapper>

      <CollaborationFormModal
        open={addCollaborationModalOpen}
        headerLabel="Add Deal Share"
        loading={createCollaborationLoading}
        form={form}
        setForm={setForm}
        formErrors={createCollaborationFieldErrors}
        setFormErrors={setCreateCollaborationFieldErrors}
        onClose={handleCloseAddCollaborationModal}
        onConfirm={handleAddCollaboration}
        dealOptions={dealOptions.Active}
      />
    </>
  );
}
