export const getFilledChipColor = (status: string) => {
  if (status === "FINISHED")
    return {
      color: "#006631",
      backgroundColor: "#E6F7EC",
    };
  if (status === "CREATED")
    return {
      color: "#202020",
      backgroundColor: "#EBEBEB",
    };
  if (status === "ERROR")
    return {
      color: "#B71C1C",
      backgroundColor: "#FFEBEE",
    };
  return {
    color: "#E65100", // Changed to dark orange
    backgroundColor: "#FFF3E0",
  };
};
