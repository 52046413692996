import React from "react";
import { Chart as ChartJS, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(TimeScale);

export interface ISolverResultChartProps {
  data: {
    labels: number[];
    data: number[];
    closestToGoal: number;
    goalValue: number;
    solverFor: string;
    goalType: string;
  };
}

export default function SolverResultChart({
  data,
}: ISolverResultChartProps): JSX.Element {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    const chart =
      chartRef &&
      new ChartJS(chartRef.current as HTMLCanvasElement, {
        type: "line",
        data: {
          labels: data.labels,
          datasets: [
            {
              label: "Solver Result",
              data: data.data,
              fill: false,
              borderColor: "#054500",
              tension: 0.5,
              pointRadius: data.data.map((value) =>
                value === data.closestToGoal ? 6 : 4,
              ),
              pointStyle: data.data.map((value) =>
                value === data.closestToGoal ? "rectRot" : "circle",
              ),
              pointBackgroundColor: data.data.map((value) =>
                value === data.closestToGoal ? "#AB5C00" : "#054500",
              ),
              pointBorderColor: data.data.map((value) =>
                value === data.closestToGoal ? "#AB5C00" : "#054500",
              ),
            },
            // {
            //   label: "Current Assumption",
            //   data: Array(data.data.length).fill(data.currentAssumption),
            //   fill: false,
            //   borderColor: "#4739F3",
            //   borderDash: [5, 5],
            //   tension: 0.5,
            // },
            {
              label: "Goal",
              data: Array(data.data.length).fill(data.goalValue),
              fill: false,
              borderColor: "#AD0000",
              borderDash: [5, 5],
              tension: 0.5,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.2,
          scales: {
            x: {
              type: "linear",
              title: {
                display: true,
                text: data.solverFor, // Replace with your desired X-axis label
              },
            },
            y: {
              title: {
                display: true,
                text: data.goalType, // Replace with your desired Y-axis label
              },
            },
          },
        },
      });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div style={{ height: "400px" }}>
      <canvas id="solver-result-chart" ref={chartRef} />
    </div>
  );
}
