import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { isNumber } from "lodash";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import LogsButton from "../../../../components/logs-button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import ConditionalProtect from "../../../../components/conditional-protect";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import DealSponsorEquityFormModal from "../../../../components/deal-sponsor-equity-form-modal";
import { useAPI, useDrawer, useLogs } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import {
  CARRYFORWARD_CREDITS,
  DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE,
  DISTRIBUTION_PERIODICITY,
} from "../../../../constants";
import {
  IDealSponsorEquity,
  IDealSponsorEquityForm,
  IDealSponsorEquityFormErrors,
  ILogsConfiguration,
} from "../../../../interfaces";

interface IProps {
  getDealSponsorEquity: (dealUuid: string) => Promise<IDealSponsorEquity[]>;
  updateDealSponsorEquity: (
    dealUuid: string,
    taxEquityId: number,
    form: IDealSponsorEquityForm,
  ) => Promise<IDealSponsorEquity>;
}

export default function DealSizingSponsorEquityView({
  getDealSponsorEquity,
  updateDealSponsorEquity,
}: IProps) {
  const styles = useStyles();

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [dealSponsorEquity, setDealSponsorEquity] =
    React.useState<IDealSponsorEquity>();
  const [updateSponsorEquityModalOpen, setUpdateSponsorEquityModalOpen] =
    React.useState<boolean>(false);
  const [updateSponsorEquityForm, setUpdateSponsorEquityForm] =
    React.useState<IDealSponsorEquityForm>(
      DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE,
    );

  const {
    callAPI: getDealSponsorEquityCallAPI,
    errored: getSponsorEquityFailed,
    loading: loadingSponsorEquity,
  } = useAPI((dealUuid: string) => getDealSponsorEquity(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealSponsorEquityCallAPI,
    fieldErrors: updateDealSponsorEquityFormErrors,
    setFieldErrors: setUpdateDealSponsorEquityFormErrors,
    loading: updateDealSponsorEquityLoading,
  } = useAPI<IDealSponsorEquity, IDealSponsorEquityFormErrors>(
    (dealUuid: string, taxEquityId: number, form: IDealSponsorEquityForm) =>
      updateDealSponsorEquity(dealUuid, taxEquityId, form),
  );

  React.useEffect(() => {
    getDealSponsorEquityCallAPI(dealIdToUse).then((res) => {
      res && setDealSponsorEquity(res[0]);
    });
  }, [dealIdToUse]);

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  const handleOpenUpdateSizingSponsorEquityFormModal = async () => {
    if (dealSponsorEquity) {
      const {
        after_tax_discount_rate,
        carryforward_credits_and_losses,
        federal_tax_rate,
        federal_tax_rate_change_year,
        new_federal_tax_rate,
        state_tax_rate,
        pre_tax_discount_rate,
        distribution_periodicity,
        is_partnership_deal,
      } = dealSponsorEquity;
      setUpdateSponsorEquityForm({
        after_tax_discount_rate,
        carryforward_credits_and_losses,
        federal_tax_rate,
        federal_tax_rate_change_year,
        new_federal_tax_rate,
        state_tax_rate,
        pre_tax_discount_rate,
        change_in_federal_tax_rate: isNumber(
          new_federal_tax_rate || federal_tax_rate_change_year,
        )
          ? "Y"
          : "N",
        distribution_periodicity,
        is_partnership_deal,
      });
      setUpdateSponsorEquityModalOpen(true);
    }
  };

  const handleCloseUpdateSizingSponsorEquityFormModal = async () => {
    setUpdateSponsorEquityModalOpen(false);
  };

  const handleOnEditDealSponsorEquity = async (
    form: IDealSponsorEquityForm,
  ) => {
    const sponsorEquity = await updateDealSponsorEquityCallAPI(
      caseDealUuid,
      Number(dealSponsorEquity?.id),
      form,
    );
    if (sponsorEquity) {
      setDealSponsorEquity(sponsorEquity);
    }

    return sponsorEquity;
  };

  const dealSizingSponsorEquityLogConfiguration: ILogsConfiguration = {
    id: Number(dealSponsorEquity?.id),
    type: "sponsorequityconfiguration",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealSizingSponsorEquityLogConfiguration.type,
      dealSizingSponsorEquityLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingSponsorEquity}
        error={getSponsorEquityFailed}
      >
        {!dealSponsorEquity && (
          <ConditionalProtect type="deal">
            <Box className={styles.classes.emptyContainer}>
              <Box>
                <Typography
                  variant="body1"
                  className={styles.classes.createInfo}
                >
                  Sponsor Equity on this deal does not exist.
                </Typography>
              </Box>
            </Box>
          </ConditionalProtect>
        )}
        {dealSponsorEquity && (
          <>
            <Box className={cn("flex justify-end items-center gap-2")}>
              <ToggleSizingOutputButton />
              <LogsButton onClick={handleOnOpenLogs} />

              <ConditionalProtect type="deal">
                {!isOnCase && (
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenUpdateSizingSponsorEquityFormModal}
                  />
                )}
              </ConditionalProtect>
            </Box>

            <Box className={styles.classes.content}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <DetailsCard
                    heading="Key Parameters"
                    sections={[
                      {
                        fields: [
                          {
                            label: "Pre-Tax Discount Rate",
                            value: {
                              text:
                                Number(
                                  dealSponsorEquity?.pre_tax_discount_rate ?? 0,
                                )?.toFixed(4) + "%",
                            },
                          },
                          {
                            label: "After-Tax Discount Rate",
                            value: {
                              text:
                                Number(
                                  dealSponsorEquity?.after_tax_discount_rate ??
                                    0,
                                ).toFixed(4) + "%",
                            },
                          },
                          {
                            label: "Carryforward Credits and Losses",
                            value: {
                              text:
                                CARRYFORWARD_CREDITS[
                                  dealSponsorEquity?.carryforward_credits_and_losses as keyof typeof CARRYFORWARD_CREDITS
                                ] ?? "N/A",
                            },
                          },
                          {
                            label: "Federal Tax Rate",
                            value: {
                              text:
                                Number(
                                  dealSponsorEquity?.federal_tax_rate ?? 0,
                                ).toFixed(4) + "%",
                            },
                          },
                          ...(isNumber(
                            dealSponsorEquity?.new_federal_tax_rate ||
                              dealSponsorEquity?.federal_tax_rate_change_year,
                          )
                            ? [
                                {
                                  label: "New Federal Tax Rate",
                                  value: {
                                    text:
                                      Number(
                                        dealSponsorEquity?.new_federal_tax_rate ??
                                          0,
                                      ).toFixed(4) + "%",
                                  },
                                },
                                {
                                  label: "Tax Rate Change Year",
                                  value: {
                                    text: isNumber(
                                      dealSponsorEquity?.federal_tax_rate_change_year,
                                    )
                                      ? String(
                                          dealSponsorEquity?.federal_tax_rate_change_year,
                                        )
                                      : "N/A",
                                  },
                                },
                              ]
                            : []),
                          {
                            label: "State Tax Rate",
                            value: {
                              text:
                                Number(
                                  dealSponsorEquity?.state_tax_rate ?? 0,
                                ).toFixed(4) + "%",
                            },
                          },
                          {
                            label: "Composite Tax Rate",
                            value: {
                              text:
                                Number(
                                  dealSponsorEquity?.composite_tax_rate ?? 0,
                                ).toFixed(4) + "%",
                            },
                          },
                          {
                            label: "New Composite Tax Rate",
                            value: {
                              text:
                                Number(
                                  dealSponsorEquity?.new_composite_tax_rate ??
                                    0,
                                ).toFixed(4) + "%",
                            },
                          },
                          // hide if deal is a partnership (TE/CE)
                          ...(!dealSponsorEquity?.is_partnership_deal
                            ? [
                                {
                                  label: "Distribution Periodicity",
                                  value: {
                                    text: DISTRIBUTION_PERIODICITY[
                                      dealSponsorEquity?.distribution_periodicity as keyof typeof DISTRIBUTION_PERIODICITY
                                    ],
                                  },
                                },
                              ]
                            : []),
                        ],
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </ViewWrapper>

      <DealSponsorEquityFormModal
        open={updateSponsorEquityModalOpen}
        loading={updateDealSponsorEquityLoading}
        form={updateSponsorEquityForm}
        formErrors={updateDealSponsorEquityFormErrors}
        setFormErrors={setUpdateDealSponsorEquityFormErrors}
        setForm={setUpdateSponsorEquityForm}
        onClose={handleCloseUpdateSizingSponsorEquityFormModal}
        onConfirm={handleOnEditDealSponsorEquity}
        headerLabel="Edit Sponsor Equity"
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealSizingSponsorEquityLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealSizingSponsorEquityLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
