export const PROJECT_API_ROUTES = {
  base: {
    get: "project/",
    getProjectOfDeal: "deal/:dealUuid/projects/",
    getProjectDetails: "project/:projectUuid/",
    delete: "project/:projectUuid/",
    create: "project/",
    update: "project/:projectUuid/",
    duplicate: "project/:projectUuid/clone/",
    getDetailsFat: "project/:projectUuid/fat/",
    downloadProjectModel:
      "project/:projectUuid/model-download/?periodicity=:periodicity",
  },
  cost: {
    summary: {
      get: `project/:projectUuid/project_cost/summary/`,
      download: `project/:projectUuid/pre-cod-proforma/download/?cost_type=:cost_type`,
      getCostScheduleDetail: `project/:projectUuid/pre-cod-proforma/?cost_type=:cost_type`,
      downloadCostScheduleDetail: `project/:projectUuid/pre-cod-proforma/?cost_type=:cost_type&output=xlsx`,
    },
    costUpdate: `project/:projectUuid/project_cost/:costId/update/`,
    costList: `/project/:projectUuid/project_cost/`,
    costItem: {
      get: `project/:projectUuid/project_cost/:costId/cost_item/:itemId/`,
      create: `project/:projectUuid/project_cost/:costId/cost_item/`,
      update: `project/:projectUuid/project_cost/:costId/cost_item/:itemId/`,
      delete: `project/:projectUuid/project_cost/:costId/cost_item/:itemId/`,
      getComponents: `project/:projectUuid/project_cost/:projectCostId/cost_item/:projectCostItemId/components/`,
      downloadComponents: `project/:projectUuid/project_cost/:projectCostId/cost_item/:projectCostItemId/components/?output=xlsx`,
    },
    creditSupport: {
      get: `project/:projectUuid/credit-support/`,
      getDetails: `project/:projectUuid/credit-support/:creditSupportId/`,
      create: `project/:projectUuid/credit-support/`,
      update: `project/:projectUuid/credit-support/:creditSupportId/`,
      delete: `project/:projectUuid/credit-support/:creditSupportId/`,
      getCreditSupportDetailSchedule: `project/:projectUuid/credit-support/:projectCreditSupportItemId/components/`,
    },
    get: `project/:projectUuid/cost/`,
    create: `project/:projectUuid/cost/`,
    update: `project/:projectUuid/cost/:costId/`,
  },
  valuation: {
    get: `project/:projectUuid/valuation/`,
    getCalcuations: `project/:projectUuid/valuation/calculations/`,
    create: `project/:projectUuid/valuation/`,
    update: `project/:projectUuid/valuation/:valuationId/`,
    downloadCalculations_Report: `project/:projectUuid/valuation/calculations/?output=xlsx`,
  },
  expense: {
    get: "project/:projectUuid/expenses/",
    getExpenseDetails: "project/:projectUuid/expenses/:expenseId/",
    create: "project/:projectUuid/expenses/",
    update: "project/:projectUuid/expenses/:expenseId/",
    delete: "project/:projectUuid/expenses/:expenseId/",
    expenseComponents: "project/:projectUuid/expenses/:expenseId/components",
    downloadExpenseComponents:
      "project/:projectUuid/expenses/:expenseId/components/?output=xlsx",
  },
  depreciation: {
    get: "project/:projectUuid/depreciation/",
    getStream:
      "project/:projectUuid/depreciation/:depreciationId/streams/:streamId/",
    createStream: "project/:projectUuid/depreciation/:depreciationId/streams/",
    updateStream:
      "project/:projectUuid/depreciation/:depreciationId/streams/:streamId/",
    deleteStream:
      "project/:projectUuid/depreciation/:depreciationId/streams/:streamId/",
  },
  production: {
    get: "project/:projectUuid/production/",
    create: "project/:projectUuid/production/",
    update: "project/:projectUuid/production/:productionId/",
    components: "project/:projectUuid/production/components/",
    downloadComponents:
      "project/:projectUuid/production/components/?output=xlsx",
  },
  revenueContracts: {
    get: "project/:projectUuid/revenue-contracts/",
    getDetails: "project/:projectUuid/revenue-contracts/:revenueId/",
    create: "project/:projectUuid/revenue-contracts/",
    update: "project/:projectUuid/revenue-contracts/:revenueId/",
    delete: "project/:projectUuid/revenue-contracts/:revenueId/",
    getMerchantRevenues: "project/:projectUuid/merchant-revenue/",
    getMerchantRevenue: "project/:projectUuid/merchant-revenue/:revenueId/",
    updateMerchantRevenue: "project/:projectUuid/merchant-revenue/:revenueId/",
    deleteMerchantRevenue: "project/:projectUuid/merchant-revenue/:revenueId/",
    addMerchantRevenue: "project/:projectUuid/merchant-revenue/",
    contractedRevenueComponents:
      "project/:projectUuid/revenue-contracts/:revenueId/components",
    merchantRevenueComponents:
      "project/:projectUuid/merchant-revenue/:revenueId/components",
    downloadRevenueContractComponents:
      "project/:projectUuid/revenue-contracts/:revenueId/components/?output=xlsx",
    downloadMerchantRevenueComponents:
      "project/:projectUuid/merchant-revenue/:revenueId/components/?output=xlsx",
  },
  scheduleOverrides: {
    get: "project/:projectUuid/schedule-overrides/",
    create: "project/:projectUuid/schedule-overrides/",
    update: "project/:projectUuid/schedule-overrides/:overrideId/",
  },
  taxCredit: {
    get: "project/:projectUuid/tax-credit/",
    create: "project/:projectUuid/tax-credit/",
    update: "project/:projectUuid/tax-credit/:taxCreditId/",
    getPTC_Report: "project/:projectUuid/tax-credit/ptc/",
    downloadPTC_Report: "project/:projectUuid/tax-credit/ptc/?output=xlsx",
  },
  timing: {
    get: `project/:projectUuid/timing/`,
    create: `project/:projectUuid/timing/`,
    update: `project/:projectUuid/timing/:timingId/`,
    delete: `project/:projectUuid/timing/:timingId/`,
    dateSchedule: `project/:projectUuid/timing/date_schedule`,
    dateScheduleTable: `project/:projectUuid/timing/date_schedule/table?period_type=:periodType`,
  },
  projectProforma: {
    get: "project-proforma/:projectUuid/?periodicity=:periodicity&basis=:basis",
  },
  other: {
    otherFlows: {
      add: "project/:projectUuid/other-flows/",
      get: "project/:projectUuid/other-flows/",
      getDetail: "project/:projectUuid/other-flows/:id",
      edit: "project/:projectUuid/other-flows/:id/",
      delete: "project/:projectUuid/other-flows/:id/",
    },
  },
};

export const DEAL_API_ROUTES = {
  base: {
    get: "deal/",
    getDetails: "deal/:dealUuid/",
    delete: "deal/:dealUuid/",
    create: "deal/",
    update: "deal/:dealUuid/",
    duplicate: "deal/:dealUuid/clone/",
    getDetailsFat: "deal/:dealUuid/fat",
  },
  cost: {
    summary: {
      getCostSchedule: `deal/:dealUuid/pre-cod-proforma/`,
      download: `deal/:dealUuid/pre-cod-proforma/?output=xlsx`,
    },
    get: "deal/:dealUuid/cost/?cost_input_type=:cost_input_type",
    getCreditSupportSummart:
      "deal/:dealUuid/credit-support-summary/?cost_input_type=:cost_input_type",
    deal_cost: "deal/:dealUuid/deal-cost/",
  },
  taxCredit: {
    get: "deal/:dealUuid/tax-credits/",
    getPTC_Data: "deal/:dealUuid/tax-credits/ptc/",
    downloadPTC_Data: "deal/:dealUuid/tax-credits/ptc/?output=xlsx",
  },
  dealProjectDepreciation: {
    get: "deal/:dealUuid/project-depreciation/",
    update: "deal/:dealUuid/project-depreciation/",
  },
  depreciation: {
    get: "deal/:dealUuid/depreciation/",
    create: "deal/:dealUuid/depreciation/",
    update: "deal/:dealUuid/depreciation/:depreciationId/",
  },
  taxEquity: {
    get: "deal/:dealUuid/tax-equity/",
    create: "deal/:dealUuid/tax-equity/",
    update: "deal/:dealUuid/tax-equity/:taxEquityId/",
    delete: "deal/:dealUuid/tax-equity/:taxEquityId/",
  },
  debt: {
    get: "deal/:dealUuid/term-debt/",
    create: "deal/:dealUuid/term-debt/",
    update: "deal/:dealUuid/term-debt/:debtId/",
    delete: "deal/:dealUuid/term-debt/:debtId/",
  },
  debtConstruction: {
    get: "deal/:dealUuid/construction-debt-general-sizing/",
    create: "deal/:dealUuid/construction-debt-general-sizing/",
    update: "deal/:dealUuid/construction-debt-general-sizing/:debtId/",
    delete: "deal/:dealUuid/construction-debt-general-sizing/:debtId/",
    constructionLoan: {
      get: "deal/:dealUuid/construction-debt/",
      create: "deal/:dealUuid/construction-debt/",
      update: "deal/:dealUuid/construction-debt/:debtId/",
      delete: "deal/:dealUuid/construction-debt/:debtId/",
    },
    investorBridgeLoan: {
      get: "deal/:dealUuid/investor-bridge-debt/",
      create: "deal/:dealUuid/investor-bridge-debt/",
      update: "deal/:dealUuid/investor-bridge-debt/:debtId/",
      delete: "deal/:dealUuid/investor-bridge-debt/:debtId/",
    },
    transferBridgeLoan: {
      get: "deal/:dealUuid/transfer-bridge-debt/",
      create: "deal/:dealUuid/transfer-bridge-debt/",
      update: "deal/:dealUuid/transfer-bridge-debt/:debtId/",
      delete: "deal/:dealUuid/transfer-bridge-debt/:debtId/",
    },
  },
  fee: {
    get: "deal/:dealUuid/fee/",
    getFeeDetails: "deal/:dealUuid/fee/:feeId/",
    create: "deal/:dealUuid/fee/",
    update: "deal/:dealUuid/fee/:feeId/",
    delete: "deal/:dealUuid/fee/:feeId/",
  },
  timing: {
    get: "deal/:dealUuid/timing/",
    update: "deal/:dealUuid/timing/:timingId/",
  },
  dealProforma: {
    get: "deal-proforma/:dealUuid/?periodicity=:periodicity&basis=:basis",
  },
  dealTaxCreditTransfer: {
    get: "deal/:dealUuid/tax-credit-transfer/",
    update: "deal/:dealUuid/transfer-sizing/:transferSizingId/",
  },
  dealTaxCreditDirectPay: {
    get: "deal/:dealUuid/tax-credit-direct-pay/",
    update: "deal/:dealUuid/tax-credit-direct-pay/:directPayId/",
  },
  case: {
    get: "deal/:dealUuid/case/",
    create: "deal/:dealUuid/case/",
    getDetails: "deal/:dealUuid/case/:caseId/",
    delete: "deal/:dealUuid/case/:caseId/",
    duplicate: "deal/:dealUuid/case/:caseId/clone/",
    update: "deal/:dealUuid/case/:caseId/",
    getLite: "deal/:dealUuid/case/lite/",
  },
  analysis: {
    update: "deal-comparison/:uuid/",
    get: "deal-comparison/",
    create: "deal-comparison/",
  },
  dealOtherSizing: {
    get: "deal/:dealUuid/other-sizing/",
    update: "deal/:dealUuid/other-sizing/:sizingId/",
  },
  dealSaleLeaseback: {
    create: "deal/:dealUuid/slb-sizing/:id/",
    get: "deal/:dealUuid/slb-sizing/",
    edit: "deal/:dealUuid/slb-sizing/:id/",
    details: "deal/:dealUuid/slb-sizing/:id/",
  },
  sponsorEquity: {
    get: "deal/:dealUuid/sponsor-equity/",
    create: "deal/:dealUuid/sponsor-equity/",
    update: "deal/:dealUuid/sponsor-equity/:sponsorEquityId/",
  },
  transferPortfolio: {
    get: `deal/:dealUuid/transfer-config/`,
    update: `deal/:dealUuid/transfer-config/:transferConfigId/`,
  },
};

export const DEAL_COMPARISON_API_ROUTES = {
  base: {
    create: "deal-comparison/",
    get: "deal-comparison/",
    getDetails: "deal-comparison/:uuid/",
    update: "deal-comparison/:uuid/",
    delete: "deal-comparison/:uuid/",
  },
  analysis: {
    getInputs:
      "deal-comparison/:dealComparisonUuid/deal/:dealUuid/input-sizing-summary/",
    getOutputs: "deal-comparison/:dealComparisonUuid/deal/:dealUuid/outputs/",
  },
};

export const PERSONAL_ACCOUNT_API_ROUTES = {
  getSharedDeals: "external/external-share-list",
};

export const REPORT_API_ROUTES = {
  deal: {
    get: "reporting/deal/:dealUuid/?perspective=:reportPerspective&type=:reportType&term=:reportTerm&periodicity=:periodicity",
    getDownloadUserDealReport:
      "reporting/deal/:dealUuid/download/?perspective=:reportPerspective&type=:reportType&term=:reportTerm&periodicity=:periodicity",
    getSponsorEquityBOL_ReportSummary:
      "/reporting/deal/:dealUuid/sponsor-equity-bol/summary/",
    getDeveloperReportSummary: "reporting/deal/:dealUuid/developer/summary/",
    getTaxEquityReportSummary: "reporting/deal/:dealUuid/tax-equity/summary/",
    getSponsorEquityReportSummary:
      "reporting/deal/:dealUuid/sponsor-equity/summary/",
    getDebtReportSummary: "reporting/deal/:dealUuid/term-debt/summary/",
    getConstructionDebtSummary:
      "reporting/deal/:dealUuid/construction-debt/summary/",
    getDealOutputPortfolioCostSchedule:
      "reporting/deal/:dealUuid/portfolio/cost-schedule/",
    downloadPortfolioCostScheduleData:
      "reporting/deal/:dealUuid/portfolio/cost-schedule/?output=xlsx",
    getDealOutputPortfolioCostSummary:
      "reporting/deal/:dealUuid/portfolio/cost-summary/",
    getByStage: "deals/by-stage/",
    getNonTransferCashChart:
      "reporting/deal/:dealUuid/cash-charts/?is_percent=:is_percent",
    getTransferCashChart:
      "reporting/deal/:dealUuid/transfer-cash-charts/?is_percent=:is_percent",
    getDealPartnershipDepreciationChart:
      "reporting/deal/:dealUuid/partnership/depreciation-charts/?is_percent=:is_percent",
    getDealNetOperatingIncomeChart:
      "reporting/deal/:dealUuid/partnership/net-operating-income-charts/?is_percent=:is_percent",
    getDealOtherIncomeChart:
      "reporting/deal/:dealUuid/partnership/other-income-loss-charts/?is_percent=:is_percent",
    getDealDebtServiceChart:
      "reporting/deal/:dealUuid/term-debt/debt-service-charts/?is_percent=:is_percent",
    getDealDebtBalanceChart:
      "reporting/deal/:dealUuid/term-debt/debt-balance-charts/?is_percent=:is_percent",
    getDealTaxEquityChart:
      "reporting/deal/:dealUuid/tax-equity/tax-charts/?is_percent=:is_percent",
    getDealTaxEquityCashChart:
      "reporting/deal/:dealUuid/tax-equity/cashflow-charts/?is_percent=:is_percent",
    getDealSponsorCashEquityChart:
      "reporting/deal/:dealUuid/sponsor-equity/sponsor-charts/?is_percent=:is_percent",
    getDealSponsorEquityCashChart:
      "reporting/deal/:dealUuid/sponsor-equity/cashflow-charts/?is_percent=:is_percent",
    getCapitalChartData:
      "reporting/deal/:dealUuid/partnership/capital-sources/",
    getOutputPartnershipSplit: "reporting/deal/:dealUuid/partnership/split/",
    getOutputPartnershipSummary:
      "reporting/deal/:dealUuid/partnership/summary/",
    getTransferReportSummary: "reporting/deal/:dealUuid/transfer/summary/",
    getDealTransferCash: "reporting/deal/:dealUuid/transfer/cash/",
    getOutputPartnershipNetValueComponentChart: `reporting/deal/:dealUuid/partnership/net-value-component-charts/`,
    getOutputLessorSummary: "reporting/deal/:dealUuid/lessor/summary/",
    getOutputLesseeSummary: "reporting/deal/:dealUuid/lessee/summary/",
    getOutputPortfolioSourcesAndUsesConstruction:
      "reporting/deal/:dealUuid/portfolio/sources-and-uses/construction/",
    getOutputPortfolioSourcesAndUsesCod:
      "reporting/deal/:dealUuid/portfolio/sources-and-uses/cod/",
  },
  project: {
    getByStage: "projects/by-stage/",
  },
  pipeline: {
    getByYear: "projects/pipeline/by-year/",
  },
};

export const CO_ZE_API_ROUTES = {
  base: {
    get: "coze/",
    create: "coze/",
    update: "coze/:coZeId/",
    delete: "coze/:coZeId/",
    updateCoZeConvertToDeal: "coze/:coZeId/convert-to-deal/",
  },
};

export const LOG_API_ROTES = {
  logs: {
    get: "/common/audit-logs",
  },
  notes: {
    get: "/common/user-notes/",
    create: "/common/user-notes/",
    update: "/common/user-notes/:id/",
    delete: "/common/user-notes/:id/",
  },
};

export const CONFIGURATION_ROUTES = {
  base: {
    get: "org/",
    usage: "org/usage/",
  },
  data: {
    curves: {
      curveGroups: `common/curve-groups/`,
      editCurveGroup: `common/curve-groups/:cgUuid/`,
      deleteCurveGroup: `common/curve-groups/:cgUuid/`,
      curves: `common/curves/`,
      editCurve: `common/curves/:cUuid/`,
      deleteCurve: `common/curves/:cUuid/`,
      getCurve: `common/curves/:cUuid/`,
      modifyCurve: `common/transform-array-data`,
    },
    counterparties: {
      get: `counterparties/`,
      getSingle: `counterparties/:id/`,
      create: `counterparties/`,
      update: `counterparties/:id/`,
      delete: `counterparties/:id/`,
      addUser: `counterparty-users/`,
      editUser: `counterparty-users/:id/`,
      deleteUser: `counterparty-users/:id/`,
    },
  },
  dates_schedule: {
    get: `/common/get-schedule-dates`,
  },
  user: {
    getUser: "/clerk/me/",
  },
};

export const COLLABORATION_ROUTES = {
  base: {
    get: "/deal-share/",
    create: "/deal-share/",
    getDetails: "/deal-share/:uuid/",
    update: "/deal-share/:uuid/",
    getDealCounterparties: "/deal-share/:collaborationUuid/counter-party/",
    addDealCounterparty: "/deal-share/:collaborationUuid/counter-party/",
    editDealCounterparty: "/deal-share/:collaborationUuid/counter-party/:id/",
    deleteDealCounterparty: "/deal-share/:collaborationUuid/counter-party/:id/",
  },
  cases: {
    sharedCases: {
      get: "/deal-share/:uuid/shared-case/",
      create: "/deal-share/:uuid/shared-case/",
      update: "/deal-share/:uuid/shared-case/:caseId/",
      delete: "/deal-share/:uuid/shared-case/:caseId/",
    },
  },
};

export const DASHBOARD_ROUTES = {
  metrics: {
    get: "/global/summary/",
  },
  charts: {
    get: "/projects/splits/",
  },
};

export const SOLVER_API_ROUTES = {
  base: {
    get: "/solver/",
    create: "/solver/",
    getDetails: "/solver/:uuid/",
    getResults: "/solver/:uuid/result/",
    getStatus: "/solver/:uuid/status/",
  },
};
