import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import MuiButton from "@mui/material/Button";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useStyles from "./styles";
import TransferTab from "./transfer-tab";
import TermDebtTab from "./term-debt-tab";
import SponsorEquityTab from "./sponsor-equity-tab";
import TaxEquityTab from "./tax-equity-tab";
import PartnershipTab from "./partnership-tab";
import Button from "../../components/button";
import CoZeForm from "../../components/co-ze-form";
import ViewWrapper from "../../components/view-wrapper";
import ReportTable from "../../components/report-table";
import DetailsCard from "../../components/details-card";
import ContentWrappedTabs from "../../components/content-wrapped-tabs";
import ConvertToDealFormModal from "../../components/co-ze-convert-deal-form-modal";
import CheckboxInput from "../../components/checkbox-input";
import { setDeleteModalPropsAction } from "../../utils/redux/slices";
import { useAxios } from "../../components/axios-provider";
import { useAPI } from "../../utils/hooks";
import {
  generateCostDetailsCardSections,
  generateDealDetailsCardSections,
  generateExpenseDetailsCardSections,
  generateGeneralDetailsCardSections,
  generateProductionDetailsCardSections,
  generateRevenueDetailsCardSections,
  generateTaxCreditDetailsCardSections,
  generateTermDebtDetailsCardSections,
  generateTimingDetailsCardSections,
} from "../../utils/helpers/co-ze.helpers";
import {
  getOutputPartnershipSummaryUrl,
  getTaxEquityReportSummaryUrl,
  getSponsorEquityReportSummaryUrl,
  getDebtReportSummaryUrl,
  getTransferReportSummaryUrl,
  generatePartnershipAllocRows,
  getOutputPartnershipSplitUrl,
  getOutputSplitTableRows,
  generateIrrPartnershipSummaryRows,
  generateShortTaxEquityReportSummary,
  generateIrrTaxEquityReportSummary,
  generateIrrSponsorEquityReportSummary,
  generateShortSponsorEquityReportSummary,
  generateDebtReportSummary,
  generateShortDebtReportSummary,
  generateIrrDebtReportSummary,
  generateIrrTransferReportSummary,
  generateShortTransferReportSummary,
  generateTransferReportSummary,
} from "../../utils/helpers";
import {
  USER_PERMISSIONS,
  DEFAULT_CO_ZE_FORM_VAULES,
  DEFAULT_CO_ZE_CONVERT_DEAL_FORM_VAULES,
  CO_ZE_TAX_EQUITY_TABLE_IRR_ROWS_TO_IGNORE,
  PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP,
} from "../../constants";
import {
  ITab,
  ITableRow,
  ICoZe,
  ICoZeForm,
  ICoZeFormErrors,
  ITaxEquityReportSummary,
  ITaxEquityReportSummaryChart,
  IPartnershipReportSummary,
  IPartnershipReportSummaryChart,
  ICoZeConvertToDealForm,
  ICoZeConvertToDealFormErrors,
  ICoZeConvertToDealSuccess,
  ISponsorEquityReportSummary,
  ISponsorEquityReportSummaryMaster,
  ITaxEquityReportSummaryMaster,
  IPartnershipReportSummaryMaster,
  IDebtReportSummary,
  IDebtReportSummaryChart,
  IDebtReportSummaryMaster,
  ITransferReportSummary,
  ITransferReportSummaryChart,
  ITransferReportSummaryMaster,
  ICapitalChart,
  IPartnershipReportSplit,
  EmptyObjectType,
  ICozeAllocTableRows,
  ICozeTab,
} from "../../interfaces";

interface IProps {
  getCoZes: () => Promise<ICoZe | EmptyObjectType>;
  createCoZe: (form: ICoZeForm) => Promise<ICoZe>;
  updateCoZe: (coZeId: number, form: ICoZeForm) => Promise<ICoZe>;
  deleteCoZe: (id: number) => Promise<boolean>;
  getPartnershipReportSummary: (
    dealUuid: string,
  ) => Promise<IPartnershipReportSummaryMaster>;
  getTaxEquityReportSummary: (
    dealUuid: string,
  ) => Promise<ITaxEquityReportSummaryMaster>;
  getSponsorEquityReportSummary: (
    dealUuid: string,
    params: { force_unlevered: boolean },
  ) => Promise<ISponsorEquityReportSummaryMaster>;
  getDebtReportSummary: (dealUuid: string) => Promise<IDebtReportSummaryMaster>;
  getTransferReportSummary: (
    dealUuid: string,
  ) => Promise<ITransferReportSummaryMaster>;
  updateCozeConvertToDeal: (
    id: number,
    form: ICoZeConvertToDealForm,
  ) => Promise<ICoZeConvertToDealSuccess>;
  getCapitalChartData: (dealUuid: string) => Promise<ICapitalChart>;
  getOutputPartnershipSplit: (
    dealUuid: string,
  ) => Promise<IPartnershipReportSplit>;
}

export default function CoZeView({
  getCoZes,
  createCoZe,
  updateCoZe,
  deleteCoZe,
  getPartnershipReportSummary,
  getTaxEquityReportSummary,
  getSponsorEquityReportSummary,
  getDebtReportSummary,
  getTransferReportSummary,
  updateCozeConvertToDeal,
  getCapitalChartData,
  getOutputPartnershipSplit,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { has } = useAuth();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [urlParams, setUrlParams] = useSearchParams();

  const { urlLoadingPercentages } = useAxios();

  const hasPermissionToVisitCoZePage = () => {
    return (
      has &&
      has({ permission: USER_PERMISSIONS.DEALS_CRUD }) &&
      has({ permission: USER_PERMISSIONS.PROJECTS_CRUD })
    );
  };

  // If the user has no permission to view the page, redirect to the home page.
  if (!hasPermissionToVisitCoZePage()) {
    navigate("/");
  }

  const [coZe, setCoZe] = React.useState<ICoZe>();
  const [showAllDetails, setShowAllDetails] = React.useState<boolean>(false);
  const [viewEditForm, setViewEditForm] = React.useState<boolean>(false);
  const [coZeForm, setCoZeForm] = React.useState<ICoZeForm>(
    DEFAULT_CO_ZE_FORM_VAULES,
  );
  const [convertToDealForm, setConvertToDealForm] =
    React.useState<ICoZeConvertToDealForm>(
      DEFAULT_CO_ZE_CONVERT_DEAL_FORM_VAULES,
    );
  const [convertToDealFormModalOpen, setConvertToDealFormModalOpen] =
    React.useState<boolean>(false);

  const [partnershipChartData, setPartnershipChartData] =
    React.useState<IPartnershipReportSummaryChart>();
  const [partnershipReportSummary, setPartnershipReportSummary] =
    React.useState<IPartnershipReportSummary>();
  const [taxEquityReportSummaryChart, setTaxEquityReportSummaryChart] =
    React.useState<ITaxEquityReportSummaryChart>();
  const [taxEquityReportSummary, setTaxEquityReportSummary] =
    React.useState<ITaxEquityReportSummary>();
  const [sponsorEquityReportSummaryChart, setSponsorEquityReportSummaryChart] =
    React.useState<ITaxEquityReportSummaryChart>();
  const [sponsorEquityReportSummary, setSponsorEquityReportSummary] =
    React.useState<ISponsorEquityReportSummary>();
  const [capitalChartData, setCapitalChartData] =
    React.useState<ICapitalChart>();
  const [partnershipSplitreport, setPartnershipSplitreport] =
    React.useState<IPartnershipReportSplit>();
  const [showUnlevered, setShowUnlevered] = React.useState<boolean>(false);
  const [debtReportSummary, setDebtReportSummary] =
    React.useState<IDebtReportSummary>();
  const [debtReportSummaryChart, setDebtReportSummaryChart] =
    React.useState<IDebtReportSummaryChart | null>();
  const [transferReportSummary, setTransferReportSummary] =
    React.useState<ITransferReportSummary>();
  const [transferReportSummaryChart, setTransferReportSummaryChart] =
    React.useState<ITransferReportSummaryChart | null>();

  // If the 'report-type' parameter is not present, defaults to "sponsor-equity".
  const selectedTab: ICozeTab["value"] = React.useMemo(() => {
    if (!urlParams.has("report-type")) {
      return "sponsor-equity";
    }
    return urlParams.get("report-type") as ICozeTab["value"];
  }, [urlParams]);

  React.useEffect(() => {
    getCoZesCallAPI().then((res) => {
      if (res && Object.keys(res).length !== 0) {
        setCoZe(res as ICoZe);
      }
    });
  }, []);

  React.useEffect(() => {
    if (coZe && ["partnership", "portfolio"].includes(selectedTab)) {
      getPartnershipSummaryCallAPI(coZe.deal_uuid).then((response) => {
        if (response) {
          setPartnershipReportSummary(response.data);
          setPartnershipChartData(response.data.chart);
        }
      });
      getCapitalChartDataCallAPI(coZe.deal_uuid).then((response) => {
        if (response) {
          setCapitalChartData(response);
        }
      });
      if (coZe && coZe.deal_structure !== "EO") {
        getOutputPartnershipSplitCallAPI(coZe.deal_uuid).then((response) => {
          if (response) {
            setPartnershipSplitreport(response);
          }
        });
      }
    }
    if (coZe && ["tax-equity", "cash-equity"].includes(selectedTab)) {
      getTaxEquityReportSummaryCallAPI(coZe.deal_uuid).then((response) => {
        if (response) {
          setTaxEquityReportSummary(response.data);
          setTaxEquityReportSummaryChart(response.data.chart);
        }
      });
    }
    if (coZe && selectedTab === "sponsor-equity") {
      getSponsorEquityReportSummaryCallAPI(coZe.deal_uuid, showUnlevered).then(
        (response) => {
          if (response) {
            setSponsorEquityReportSummary(response.data);
            setSponsorEquityReportSummaryChart(response.data.chart);
          }
        },
      );
    }
    if (coZe && selectedTab === "term-debt") {
      getDebtReportSummaryCallAPI(coZe.deal_uuid).then((response) => {
        if (response) {
          setDebtReportSummary(response.data);
          setDebtReportSummaryChart(response.data.chart);
        }
      });
    }
    if (coZe && ["transfer", "direct-pay"].includes(selectedTab)) {
      getTransferReportSummaryCallAPI(coZe.deal_uuid).then((response) => {
        if (response) {
          setTransferReportSummary(response.data);
          setTransferReportSummaryChart(response.data.chart);
        }
      });
    }
  }, [coZe, selectedTab, showUnlevered]);

  const {
    callAPI: getCoZesCallAPI,
    errored: getCoZesFailed,
    loading: getCoZesLoading,
  } = useAPI(getCoZes, { initialLoading: true });

  const {
    callAPI: createCoZeCallAPI,
    loading: createCoZeLoading,
    fieldErrors: createCoZeFieldErrors,
    setFieldErrors: setCreateCoZeFieldErrors,
  } = useAPI<ICoZe, ICoZeFormErrors>((form) => createCoZe(form));

  const {
    callAPI: updateCoZeCallAPI,
    loading: updateCoZeLoading,
    fieldErrors: updateCoZeFieldErrors,
    setFieldErrors: setUpdateCoZeFieldErrors,
  } = useAPI<ICoZe, ICoZeFormErrors>((id, form) => updateCoZe(id, form));

  const { callAPI: deleteCoZeCallAPI } = useAPI((id) => deleteCoZe(id), {
    setConfirmModalLoading: true,
  });

  const {
    callAPI: getPartnershipSummaryCallAPI,
    errored: getPartnershipSummaryFailed,
    loading: loadingPartnershipSummary,
    errorObj: partnershipSummaryErrorObj,
    showUsageLimitHitScreen: showPartnershipUsageLimitHitScreen,
  } = useAPI((dealUuid: string) => getPartnershipReportSummary(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getTaxEquityReportSummaryCallAPI,
    errored: getTaxEquityReportSummaryFailed,
    loading: loadingTaxEquityReportSummary,
    errorObj: taxEquityReportSummaryErrorObj,
    showUsageLimitHitScreen: showTaxEquityUsageLimitHitScreen,
  } = useAPI((dealUuid: string) => getTaxEquityReportSummary(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getSponsorEquityReportSummaryCallAPI,
    errored: getSponsorEquityReportSummaryFailed,
    loading: loadingSponsorEquityReportSummary,
    errorObj: sponsorEquityReportSummaryErrorObj,
    showUsageLimitHitScreen: showSponsorEquityUsageLimitHitScreen,
  } = useAPI(
    (dealUuid: string, force_unlevered: boolean) =>
      getSponsorEquityReportSummary(dealUuid, { force_unlevered }),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateCozeConvertToDealCallAPI,
    fieldErrors: convertToDealFormErrors,
    setFieldErrors: setConvertToDealFormErrors,
    loading: convertToDealLoading,
  } = useAPI<ICoZeConvertToDealSuccess, ICoZeConvertToDealFormErrors>(
    (id, convertToDealForm) => updateCozeConvertToDeal(id, convertToDealForm),
  );

  const { callAPI: getCapitalChartDataCallAPI } = useAPI(
    (dealUuid: string) => getCapitalChartData(dealUuid),
    {
      initialLoading: true,
    },
  );

  const partnershipTableIrrRows: ITableRow[] = React.useMemo(() => {
    if (partnershipReportSummary) {
      return generateIrrPartnershipSummaryRows(
        partnershipReportSummary.metrics,
      );
    }
    return [];
  }, [partnershipReportSummary]);

  const taxEquityShortTableRows: ITableRow[] = React.useMemo(() => {
    if (taxEquityReportSummary) {
      return generateShortTaxEquityReportSummary(
        taxEquityReportSummary.metrics,
      );
    }
    return [];
  }, [taxEquityReportSummary]);

  const taxEquityTableIrrRows: ITableRow[] = React.useMemo(() => {
    if (taxEquityReportSummary) {
      return generateIrrTaxEquityReportSummary(
        taxEquityReportSummary.metrics,
        undefined,
        CO_ZE_TAX_EQUITY_TABLE_IRR_ROWS_TO_IGNORE,
      );
    }
    return [];
  }, [taxEquityReportSummary]);

  const sponsorEquityShortTableRows: ITableRow[] = React.useMemo(() => {
    if (sponsorEquityReportSummary) {
      return generateShortSponsorEquityReportSummary(
        sponsorEquityReportSummary.metrics,
      );
    }
    return [];
  }, [sponsorEquityReportSummary]);

  const sponsorEquityTableIrrRows: ITableRow[] = React.useMemo(() => {
    if (sponsorEquityReportSummary) {
      return generateIrrSponsorEquityReportSummary(
        sponsorEquityReportSummary.metrics,
      );
    }
    return [];
  }, [sponsorEquityReportSummary]);

  const allocTableRows: ICozeAllocTableRows = React.useMemo(() => {
    return partnershipReportSummary
      ? generatePartnershipAllocRows(
          partnershipReportSummary.alloc,
          coZe?.deal_structure,
        )
      : { columns: [], rows: [] };
  }, [coZe, partnershipReportSummary]);

  const taxTableRows: ITableRow[] = React.useMemo(() => {
    return partnershipSplitreport
      ? getOutputSplitTableRows(partnershipSplitreport)
      : [];
  }, [partnershipSplitreport]);

  const {
    callAPI: getTransferReportSummaryCallAPI,
    errored: getTransferReportSummaryFailed,
    loading: loadingTransferReportSummary,
    errorObj: transferSummaryErrorObj,
  } = useAPI((dealUuid: string) => getTransferReportSummary(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getDebtReportSummaryCallAPI,
    errored: getDebtReportSummaryFailed,
    loading: loadingDebtReportSummary,
    errorObj: getDebtReportSummaryErrorObj,
  } = useAPI((dealUuid: string) => getDebtReportSummary(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getOutputPartnershipSplitCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
  } = useAPI((dealUuid: string) => getOutputPartnershipSplit(dealUuid), {
    initialLoading: true,
  });

  const debtTableRows: ITableRow[] | null = React.useMemo(() => {
    if (debtReportSummary) {
      return generateDebtReportSummary(debtReportSummary.metrics);
    }
    return [];
  }, [debtReportSummary]);

  const debtShortTableRows: ITableRow[] | null = React.useMemo(() => {
    if (debtReportSummary) {
      return generateShortDebtReportSummary(debtReportSummary.metrics);
    }
    return [];
  }, [debtReportSummary]);

  const debtIrrRows: ITableRow[] | null = React.useMemo(() => {
    if (debtReportSummary) {
      return generateIrrDebtReportSummary(debtReportSummary.metrics);
    }
    return [];
  }, [debtReportSummary]);

  const transferTableRows: ITableRow[] | null = React.useMemo(() => {
    if (transferReportSummary) {
      return generateTransferReportSummary(transferReportSummary.summary);
    }
    return [];
  }, [transferReportSummary]);

  const transferShortTableRows: ITableRow[] | null = React.useMemo(() => {
    if (transferReportSummary) {
      return generateShortTransferReportSummary(transferReportSummary.summary);
    }
    return [];
  }, [transferReportSummary]);

  const transferIrrRows: ITableRow[] | null = React.useMemo(() => {
    if (transferReportSummary) {
      return generateIrrTransferReportSummary(
        transferReportSummary.summary,
        transferReportSummary.irr_error,
      );
    }
    return [];
  }, [transferReportSummary]);

  const handleDeleteCoZe = async (id: number) => {
    const response = await deleteCoZeCallAPI(id);

    if (response) {
      setUrlParams({});
      setCoZe(undefined);
      window.location.reload(); // TODO refreshing page isn’t ideal long term
    }
  };

  const handleOnDelete = (id: number | undefined) => {
    if (id === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Coze",
        description: "Are you sure you want to delete Coze?",
        onConfirm: () => handleDeleteCoZe(id),
      }),
    );
  };

  const handleCloseConvertToDealFormModal = () => {
    setConvertToDealFormModalOpen(false);
  };

  const handleConvertToDealClick = () => {
    if (coZe) {
      setConvertToDealFormModalOpen(true);
      setConvertToDealForm(DEFAULT_CO_ZE_CONVERT_DEAL_FORM_VAULES);
    }
  };

  const handleConvertToDeal = async (form: ICoZeConvertToDealForm) => {
    if (!coZe) {
      return undefined;
    }
    const deal = await updateCozeConvertToDealCallAPI(
      coZe.id,
      form as ICoZeConvertToDealForm,
    );
    if (deal) {
      navigate(`/deal/${deal.deal_uuid}/case-deal/${deal.deal_uuid}/general`);
      toast.success("CoZe converted to deal successfully!", {
        toastId: "coze-conversion-success",
      });
    }
    return deal;
  };

  const handleCreateCoZe = async (form: ICoZeForm) => {
    const response = await createCoZeCallAPI(form);
    if (response) {
      setCoZe(response);
    }
    return response;
  };

  const handleTabChange = (tab: ITab) => {
    setUrlParams({ "report-type": tab.value });
  };

  const handleEditClick = () => {
    if (coZe) {
      // clear url params
      setUrlParams({});
      const { id, created, modified, deal_uuid, project_uuid, ...formData } =
        coZe;
      setCoZeForm(formData);
      setViewEditForm(true);
    }
  };

  const hideEditForm = () => {
    setViewEditForm(false);
  };

  const handleUpdateClick = async () => {
    if (!coZe) {
      return undefined;
    }
    const response = await updateCoZeCallAPI(coZe.id, coZeForm);
    if (response) {
      setCoZe(response);
      setViewEditForm(false);
      window.location.reload(); // TODO refreshing page isn’t ideal long term
    }
    return response;
  };

  const toggleShowAllDetails = () => {
    setShowAllDetails((prevShowAllDetails) => {
      if (prevShowAllDetails) {
        window.scrollTo(0, 0);
      }
      return !prevShowAllDetails;
    });
  };

  const productionMethodInputUnit = React.useMemo(() => {
    if (!coZe?.production_method) return undefined;
    return PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP[coZe.production_method];
  }, [coZe?.production_method]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowUnlevered(e.target.checked);
  };

  const showUnleveredCheckbox: boolean = React.useMemo(() => {
    return coZe?.term_debt_type !== "NONE";
  }, [coZe]);

  const coZeTabs: ICozeTab[] = React.useMemo(() => {
    if (!coZe) return [];

    const tabs: ICozeTab[] = [];

    // Partnership or Portfolio tab
    if (coZe.deal_structure !== "EO") {
      tabs.push({
        label: "Partnership",
        value: "partnership",
      });
      // Cash Equity or Tax Equity tab`
      if (coZe.deal_structure === "CEP") {
        tabs.push({
          label: "Cash Equity",
          value: "cash-equity",
        });
      } else {
        tabs.push({
          label: "Tax Equity",
          value: "tax-equity",
        });
      }
    } else {
      tabs.push({
        label: "Portfolio",
        value: "portfolio",
      });
    }

    // Sponsor Equity tab
    tabs.push({
      label: "Sponsor Equity",
      value: "sponsor-equity",
    });

    // Term Debt tab
    if (coZe.term_debt_type !== "NONE") {
      tabs.push({
        label: "Term Debt",
        value: "term-debt",
      });
    }

    // Transfer tab
    if (coZe.deal_tax_credit_structure === "TR") {
      tabs.push({
        label: "Transfer",
        value: "transfer",
      });
    }

    // Direct Pay tab
    if (coZe.deal_tax_credit_structure === "DP") {
      tabs.push({
        label: "Direct Pay",
        value: "direct-pay",
      });
    }

    return tabs;
  }, [coZe]);

  const costDetailsCardSections = React.useMemo(() => {
    if (!coZe) return [];
    const [section] = generateCostDetailsCardSections(coZe);
    return [{ fields: section.fields.slice(0, showAllDetails ? 3 : 2) }];
  }, [coZe, showAllDetails]);

  const dealDetailsCardSections = React.useMemo(() => {
    if (!coZe) return [];
    const [section] = generateDealDetailsCardSections(coZe);
    return [{ fields: section.fields.slice(0, showAllDetails ? 20 : 2) }];
  }, [coZe, showAllDetails]);

  const renderCoZeCard = () => {
    return (
      <Card sx={{ minWidth: 550 }} style={{ height: "fit-content" }}>
        <CardContent>
          <Typography variant="h4" component="div" fontWeight={500}>
            Cost of Zero Emissions
          </Typography>
          <Typography variant="body1" marginTop={2} component="div">
            A quick pricing tool for renewable energy developers. <br />
            <br />
            An easy-to-use interface with minimal inputs required. <br />
            <br />
            With 10 inputs, users can observe fully structured returns. <br />
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const PartnershipAllocationTable = () => {
    return coZe?.deal_structure !== "EO" ? (
      <ViewWrapper
        loading={loadingReport}
        error={getReportFailed}
        errorHeading={getReportErrorObj?.heading}
        errorDescription={getReportErrorObj?.description}
        loadingPercentage={
          urlLoadingPercentages[
            getOutputPartnershipSplitUrl(String(coZe?.deal_uuid))
          ]
        }
      >
        <Box title="Partnership Allocation">
          <ReportTable
            columns={allocTableRows.columns}
            rows={allocTableRows.rows}
          />
        </Box>
      </ViewWrapper>
    ) : null;
  };

  return (
    <>
      <ViewWrapper loading={getCoZesLoading} error={getCoZesFailed}>
        <Box className={styles.classes.container}>
          {!coZe && (
            <Box className={styles.classes.formContainer}>
              <CoZeForm
                type="add"
                loading={createCoZeLoading}
                form={coZeForm}
                setForm={setCoZeForm}
                formErrors={createCoZeFieldErrors}
                setFormErrors={setCreateCoZeFieldErrors}
                onConfirm={handleCreateCoZe}
                onCancelClick={hideEditForm}
              />
              {renderCoZeCard()}
            </Box>
          )}

          {coZe && viewEditForm && (
            <Box className={styles.classes.formContainer}>
              <CoZeForm
                type="edit"
                loading={updateCoZeLoading}
                form={coZeForm}
                setForm={setCoZeForm}
                formErrors={updateCoZeFieldErrors}
                setFormErrors={setUpdateCoZeFieldErrors}
                onConfirm={handleUpdateClick}
                onCancelClick={hideEditForm}
              />
              {renderCoZeCard()}
            </Box>
          )}

          {!viewEditForm && coZe && (
            <Box>
              <Box className={styles.classes.header}>
                <Button
                  label="Edit"
                  btnType="primary"
                  startIcon={<EditIcon />}
                  onClick={handleEditClick}
                />
                <Button
                  label="Convert to Deal"
                  btnType="primary"
                  canOpenUpgrade
                  startIcon={<ArrowForwardIcon />}
                  onClick={handleConvertToDealClick}
                  tooltip="This function is meant to convert a CoZe into a Deal and would appear with your other current active deals"
                />
                <Button
                  label="Delete"
                  btnType="danger"
                  onClick={() => handleOnDelete(coZe?.id)}
                />
              </Box>

              <Box className={styles.classes.content}>
                <Box className={styles.classes.section}>
                  <DetailsCard
                    tooltip="The inputs that are exposed are the minimim number of inputs in order to get results in the output section below"
                    heading="General"
                    sections={generateGeneralDetailsCardSections(coZe)}
                  />
                  {showAllDetails && (
                    <>
                      <DetailsCard
                        heading="Production"
                        sections={generateProductionDetailsCardSections(
                          coZe,
                          productionMethodInputUnit,
                        )}
                      />
                      <DetailsCard
                        heading="Timing"
                        sections={generateTimingDetailsCardSections(coZe)}
                      />
                    </>
                  )}
                </Box>

                <Box className={styles.classes.section}>
                  <DetailsCard
                    heading="Costs"
                    sections={costDetailsCardSections}
                  />
                  {showAllDetails && (
                    <>
                      <DetailsCard
                        heading="Revenues"
                        sections={generateRevenueDetailsCardSections(coZe)}
                      />
                      <DetailsCard
                        heading="Expenses"
                        sections={generateExpenseDetailsCardSections(coZe)}
                      />
                      <DetailsCard
                        heading="Tax Credit"
                        sections={generateTaxCreditDetailsCardSections(coZe)}
                      />
                    </>
                  )}
                </Box>

                <Box className={styles.classes.section}>
                  <DetailsCard
                    heading="Deal"
                    autoHeight
                    sections={dealDetailsCardSections}
                  />
                  {showAllDetails && (
                    <DetailsCard
                      heading="Term Debt"
                      sections={generateTermDebtDetailsCardSections(coZe)}
                    />
                  )}
                </Box>
              </Box>

              <MuiButton
                classes={{ root: styles.classes.showMoreButton }}
                endIcon={
                  showAllDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                onClick={toggleShowAllDetails}
              >
                Show {showAllDetails ? "Less" : "More"}
              </MuiButton>
              <Divider className={styles.classes.divider} />

              <ContentWrappedTabs
                tabs={coZeTabs}
                onClick={handleTabChange}
                selectedTab={selectedTab}
              >
                <Box style={{ minHeight: 400 }}>
                  {["partnership", "portfolio"].includes(selectedTab) && (
                    <ViewWrapper
                      loading={loadingPartnershipSummary}
                      error={getPartnershipSummaryFailed}
                      loaderPlacement="start"
                      errorHeading={partnershipSummaryErrorObj?.heading}
                      errorDescription={partnershipSummaryErrorObj?.description}
                      showLimitHitView={showPartnershipUsageLimitHitScreen}
                      loadingPercentage={
                        urlLoadingPercentages[
                          getOutputPartnershipSummaryUrl(
                            String(coZe?.deal_uuid),
                          )
                        ]
                      }
                    >
                      <PartnershipTab
                        capitalChartData={capitalChartData}
                        coZe={coZe}
                        partnershipAllocationTable={
                          <PartnershipAllocationTable />
                        }
                        partnershipChartData={partnershipChartData}
                        partnershipTableIrrRows={partnershipTableIrrRows}
                        taxTableRows={taxTableRows}
                      />
                    </ViewWrapper>
                  )}

                  {["tax-equity", "cash-equity"].includes(selectedTab) && (
                    <ViewWrapper
                      loading={loadingTaxEquityReportSummary}
                      error={getTaxEquityReportSummaryFailed}
                      loaderPlacement="start"
                      errorHeading={taxEquityReportSummaryErrorObj?.heading}
                      errorDescription={
                        taxEquityReportSummaryErrorObj?.description
                      }
                      showLimitHitView={showTaxEquityUsageLimitHitScreen}
                      loadingPercentage={
                        urlLoadingPercentages[
                          getTaxEquityReportSummaryUrl(String(coZe?.deal_uuid))
                        ]
                      }
                    >
                      <TaxEquityTab
                        coZe={coZe}
                        taxEquityReportSummaryChart={
                          taxEquityReportSummaryChart
                        }
                        taxEquityShortTableRows={taxEquityShortTableRows}
                        taxEquityTableIrrRows={taxEquityTableIrrRows}
                      />
                    </ViewWrapper>
                  )}
                  {selectedTab === "sponsor-equity" && (
                    <>
                      {showUnleveredCheckbox && (
                        <Box marginTop={-1}>
                          <CheckboxInput
                            label="Show Unlevered"
                            onChange={handleCheckboxChange}
                            checked={showUnlevered ?? false}
                            disabled={loadingSponsorEquityReportSummary}
                          />
                        </Box>
                      )}
                      <ViewWrapper
                        loading={loadingSponsorEquityReportSummary}
                        error={getSponsorEquityReportSummaryFailed}
                        loaderPlacement="start"
                        errorHeading={
                          sponsorEquityReportSummaryErrorObj?.heading
                        }
                        errorDescription={
                          sponsorEquityReportSummaryErrorObj?.description
                        }
                        showLimitHitView={showSponsorEquityUsageLimitHitScreen}
                        loadingPercentage={
                          urlLoadingPercentages[
                            getSponsorEquityReportSummaryUrl(
                              String(coZe?.deal_uuid),
                              showUnlevered,
                            )
                          ]
                        }
                      >
                        <SponsorEquityTab
                          coZe={coZe}
                          sponsorEquityReportSummaryChart={
                            sponsorEquityReportSummaryChart
                          }
                          sponsorEquityShortTableRows={
                            sponsorEquityShortTableRows
                          }
                          sponsorEquityTableIrrRows={sponsorEquityTableIrrRows}
                        />
                      </ViewWrapper>
                    </>
                  )}

                  {selectedTab === "term-debt" && (
                    <ViewWrapper
                      loading={loadingDebtReportSummary}
                      error={getDebtReportSummaryFailed}
                      loaderPlacement="start"
                      errorHeading={getDebtReportSummaryErrorObj?.heading}
                      errorDescription={
                        getDebtReportSummaryErrorObj?.description
                      }
                      loadingPercentage={
                        urlLoadingPercentages[
                          getDebtReportSummaryUrl(String(coZe?.deal_uuid))
                        ]
                      }
                    >
                      <TermDebtTab
                        debtIrrRows={debtIrrRows}
                        debtReportSummaryChart={debtReportSummaryChart}
                        debtShortTableRows={debtShortTableRows}
                        debtTableRows={debtTableRows}
                      />
                    </ViewWrapper>
                  )}

                  {["transfer", "direct-pay"].includes(selectedTab) && (
                    <ViewWrapper
                      loading={loadingTransferReportSummary}
                      error={getTransferReportSummaryFailed}
                      errorHeading={transferSummaryErrorObj?.heading}
                      errorDescription={transferSummaryErrorObj?.description}
                      loadingPercentage={
                        urlLoadingPercentages[
                          getTransferReportSummaryUrl(String(coZe?.deal_uuid))
                        ]
                      }
                    >
                      <TransferTab
                        coZe={coZe}
                        transferIrrRows={transferIrrRows}
                        transferReportSummaryChart={transferReportSummaryChart}
                        transferShortTableRows={transferShortTableRows}
                        transferTableRows={transferTableRows}
                      />
                    </ViewWrapper>
                  )}
                </Box>
              </ContentWrappedTabs>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <ConvertToDealFormModal
        headerLabel="Convert to Deal"
        open={convertToDealFormModalOpen}
        formErrors={convertToDealFormErrors}
        loading={convertToDealLoading}
        setFormErrors={setConvertToDealFormErrors}
        form={convertToDealForm}
        setForm={setConvertToDealForm}
        onClose={handleCloseConvertToDealFormModal}
        onConfirm={handleConvertToDeal}
      />
    </>
  );
}
