import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useOrganization } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";

import useStyles from "./styles";
import Button from "../../../components/button";
import Logs from "../../../components/logs";
import LogsButton from "../../../components/logs-button";
import LogsWrapper from "../../../components/logs-wrapper";
import ViewWrapper from "../../../components/view-wrapper";
import DealTimingFormModal from "../../../components/deal-timing-form-modal";
import ChartWrapper from "../../../components/chart-wrapper";
import DetailsCard from "../../../components/details-card";
import CheckboxInput from "../../../components/checkbox-input";
import ConditionalProtect from "../../../components/conditional-protect";
import TimingChart, {
  ITimingChartProps,
} from "../../../components/timing-chart";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../utils/hooks";
import {
  cn,
  generateDealTimingTableRows,
  sortArrayOfObjects,
} from "../../../utils/helpers";
import {
  IDealTiming,
  ILogsConfiguration,
  IUpdateDealTimingForm,
  IUpdateDealTimingFormErrors,
} from "../../../interfaces";
import {
  DEAL_TIMING_DEVELOPER_HORIZON,
  UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
} from "../../../constants";

interface IProps {
  getDealTiming: (dealUuid: string) => Promise<IDealTiming[]>;
  updateDealTiming: (
    dealUuid: string,
    timingId: number,
    form: IUpdateDealTimingForm,
  ) => Promise<IDealTiming>;
}

export default function DealTimingView({
  getDealTiming,
  updateDealTiming,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();

  const dealIdtoUse = organization ? caseDealUuid : dealUuid;

  const { currentDeal } = useAppSelector((s) => s.deal);

  const {
    onOpenLogs,
    onCloseLogs,
    loadMoreLogs,
    loadingLogs,
    logs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [showOperationPeriod, setShowOperationPeriod] =
    React.useState<boolean>(true);

  const [updateDealTimingModalOpen, setUpdateDealTimingModalOpen] =
    React.useState<boolean>(false);
  const [dealTiming, setDealTiming] = React.useState<IDealTiming>();
  const [form, setForm] = React.useState<IUpdateDealTimingForm>(
    UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getDealTimingCallAPI(dealIdtoUse).then((response) => {
      if (response) {
        setDealTiming(response[0]);
      }
    });
  }, [dealIdtoUse]);

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  const {
    callAPI: getDealTimingCallAPI,
    errored: getTimingFailed,
    loading: loadingTiming,
  } = useAPI((dealUuid: string) => getDealTiming(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealTimingCallAPI,
    fieldErrors: updateDealTimingFormErrors,
    setFieldErrors: setUpdateDealTimingFormErrors,
    loading: updateDealTimingLoading,
  } = useAPI<IDealTiming, IUpdateDealTimingFormErrors>(
    (dealUuid: string, timingId: number, form: IUpdateDealTimingForm) =>
      updateDealTiming(dealUuid, timingId, form),
  );

  const handleUpdateDealTiming = async (form: IUpdateDealTimingForm) => {
    const updatedForm = form;
    if (currentDeal?.tax_credit_structure !== "TR") {
      delete updatedForm.tct_buyer_tax_filing_month;
      delete updatedForm.tct_buyer_tax_year_end_month;
      delete updatedForm.tct_seller_tax_filing_month;
      delete updatedForm.tct_seller_tax_year_end_month;
    }
    const timing = await updateDealTimingCallAPI(
      caseDealUuid,
      dealTiming!.id,
      updatedForm,
    );
    timing && setDealTiming(timing);

    return timing;
  };

  const handleOpenUpdateDealTimingModal = () => {
    const {
      developer_horizon,
      tct_seller_tax_year_end_month,
      tct_buyer_tax_year_end_month,
      tct_seller_tax_filing_month,
      tct_buyer_tax_filing_month,
    } = dealTiming!;
    setForm({
      developer_horizon,
      tct_seller_tax_year_end_month,
      tct_buyer_tax_year_end_month,
      tct_seller_tax_filing_month,
      tct_buyer_tax_filing_month,
    });
    setUpdateDealTimingModalOpen(true);
  };

  const handleCloseUpdateDealTimingModal = () => {
    setUpdateDealTimingModalOpen(false);
  };

  const dealTimingChartData: ITimingChartProps | undefined =
    React.useMemo(() => {
      if (dealTiming?.project_timings) {
        const chartData: ITimingChartProps["data"] = [];
        const startDates: number[] = [];

        dealTiming?.project_timings.forEach((projectTiming) => {
          const {
            project_name,
            horizon,
            cod,
            start_date,
            end_date,
            ntp_date,
            mechanical_completion_date,
            placed_in_service_date,
            substantial_completion_date,
          } = projectTiming;

          const dataPoints = [
            // {
            //   date: new Date(start_date),
            //   color: "#FFA700",
            //   symbol: "rect",
            //   tooltip: `${project_name} Start Date`,
            //   id: "start",
            // },
            {
              date: new Date(ntp_date),
              color: "#F44336",
              symbol: "rectRot",
              tooltip: `${project_name} | Notice to Proceed`,
              id: "ntp",
            },
            {
              date: new Date(mechanical_completion_date),
              color: "#00B7C3",
              symbol: "triangle",
              tooltip: `${project_name} | Mechanical Completion Date`,
              rotation: 180,
              id: "mc",
            },
            {
              date: new Date(substantial_completion_date),
              color: "#007EC5",
              symbol: "triangle",
              tooltip: `${project_name} | Substantial Completion Date`,
              id: "sc",
            },
            {
              date: new Date(placed_in_service_date),
              color: "#AB47BC",
              symbol: "circle",
              tooltip: `${project_name} | Placed In Service Date`,
              id: "pis",
            },
            {
              date: new Date(cod),
              color: "#00B96A",
              symbol: "rect",
              tooltip: `${project_name} | COD`,
              id: "cod",
            },
            ...(showOperationPeriod
              ? [
                  {
                    date: new Date(end_date),
                    color: "#F44336",
                    symbol: "circle",
                    tooltip: `${project_name} | End Date`,
                    id: "end",
                  },
                ]
              : []),
          ];

          startDates.push(new Date(start_date).getTime());

          chartData.push(
            ...dataPoints.filter((d) =>
              horizon === "OS" ? d.id === "cod" || d.id === "end" : true,
            ),
          );
        });

        const minDate = new Date(Math.min(...startDates));

        return {
          startDate: minDate,
          endDate: new Date(),
          data: chartData,
        };
      }

      return undefined;
    }, [dealTiming, showOperationPeriod]);

  const handleShowOperationPeriodToggle = () => {
    setShowOperationPeriod((s) => !s);
  };

  const sortedDealProjectTimings = React.useMemo(() => {
    if (!dealTiming) return [];
    return sortArrayOfObjects(
      dealTiming.project_timings,
      "project_name",
      "asc",
    );
  }, [dealTiming]);

  const rows = React.useMemo(() => {
    return dealTiming
      ? generateDealTimingTableRows(sortedDealProjectTimings)
      : [];
  }, [dealTiming, sortedDealProjectTimings]);

  const dealTimingLogConfiguration: ILogsConfiguration = {
    id: Number(dealTiming?.id),
    type: "dealtiming",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealTimingLogConfiguration.type,
      dealTimingLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingTiming} error={getTimingFailed}>
        <Box>
          {dealTiming && (
            <Box>
              <Box className={styles.classes.header}>
                <Box className={cn("flex gap-4 items-center")}>
                  <ConditionalProtect type="deal">
                    <LogsButton onClick={handleOnOpenLogs} />

                    {!isOnCase && (
                      <Button
                        canOpenUpgrade
                        startIcon={<EditIcon />}
                        label="Edit"
                        btnType="primary"
                        onClick={handleOpenUpdateDealTimingModal}
                      />
                    )}
                  </ConditionalProtect>
                </Box>
              </Box>

              <Box>
                {dealTimingChartData && (
                  <Box className={styles.classes.chartContainer}>
                    <ChartWrapper title="Timing">
                      <Box>
                        <CheckboxInput
                          checked={showOperationPeriod}
                          label="Show Operations Period"
                          onChange={handleShowOperationPeriodToggle}
                        />
                        <TimingChart {...dealTimingChartData} />
                      </Box>
                    </ChartWrapper>
                  </Box>
                )}

                <Box className={styles.classes.content}>
                  <Box>
                    <DetailsCard
                      heading="Development Timing"
                      sections={[
                        {
                          fields: [
                            {
                              label: "Developer Horizon",
                              value: {
                                text: DEAL_TIMING_DEVELOPER_HORIZON[
                                  dealTiming.developer_horizon as keyof typeof DEAL_TIMING_DEVELOPER_HORIZON
                                ],
                              },
                            },
                            {
                              label: "Development Start Date",
                              value: {
                                text:
                                  currentDeal?.first_bol_date?.toString() ||
                                  "N/A",
                              },
                            },
                            {
                              label: "Development End Date",
                              value: {
                                text:
                                  currentDeal?.last_ntp_date?.toString() ||
                                  "N/A",
                              },
                            },
                            {
                              label: "Development Period",
                              value: {
                                text:
                                  currentDeal?.development_period_years +
                                  " Yrs",
                              },
                            },
                            {
                              label: "Construction Start Date",
                              value: {
                                text:
                                  currentDeal?.first_ntp_date?.toString() ||
                                  "N/A",
                              },
                            },
                            {
                              label: "Construction End Date",
                              value: {
                                text:
                                  currentDeal?.last_cod?.toString() || "N/A",
                              },
                            },
                            {
                              label: "Construction Period",
                              value: {
                                text:
                                  currentDeal?.construction_period_years +
                                  " Yrs",
                              },
                            },
                            {
                              label: "Operations Start Date",
                              value: {
                                text:
                                  currentDeal?.first_cod?.toString() || "N/A",
                              },
                            },
                            {
                              label: "Operations End Date",
                              value: {
                                text:
                                  currentDeal?.end_date?.toString() || "N/A",
                              },
                            },
                            {
                              label: "Operations Period",
                              value: {
                                text:
                                  currentDeal?.operating_life_years + " Yrs",
                              },
                            },
                          ],
                        },
                      ]}
                      autoHeight
                    />
                  </Box>

                  <Box>
                    <DetailsCard
                      heading="Closing & Funding Timing"
                      sections={[
                        {
                          fields: [
                            {
                              label: "Closing Date",
                              value: { text: dealTiming.cod },
                            },
                            {
                              label: "Investor Funding Date",
                              value: { text: dealTiming.tax_equity_date_1 },
                            },
                            {
                              label: "Term Conversion Date",
                              value: { text: dealTiming.tax_equity_date_1 },
                            },
                            {
                              label: "Deal Operating Life",
                              value: {
                                text: dealTiming.operating_life_years + " Yrs",
                              },
                            },
                            {
                              label: "Deal End Date",
                              value: { text: dealTiming.end_date },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </Box>

              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead className={styles.classes.tableHeader}>
                      <TableRow>
                        <TableCell key="header-empty"></TableCell>
                        {/* Empty header cell for the first column */}
                        {sortedDealProjectTimings.map((timing, idx) => (
                          <TableCell key={idx}>
                            <Link
                              to={`/project/${timing.project}/timing`}
                              target="_blank"
                            >
                              {timing.project_name}
                            </Link>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, idx) => {
                        return (
                          <TableRow key={idx}>
                            {row.map((cell, idx) => {
                              return (
                                <TableCell
                                  align={cell.align as "left"}
                                  key={idx}
                                >
                                  {cell.value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}

                      <TableRow className={styles.classes.boaldRow}>
                        <TableCell
                          colSpan={sortedDealProjectTimings.length + 1}
                        >
                          Operations Timing
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">
                          Project Operating Life
                        </TableCell>
                        {sortedDealProjectTimings.map((timing, idx) => {
                          return (
                            <TableCell align="left" key={idx}>
                              {timing.operating_life_years} Yrs
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">
                          Project End of Life Date (EOL)
                        </TableCell>
                        {sortedDealProjectTimings.map((timing, idx) => {
                          return (
                            <TableCell align="left" key={idx}>
                              {timing.end_date}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <DealTimingFormModal
        headerLabel="Edit Timing"
        open={updateDealTimingModalOpen}
        loading={updateDealTimingLoading}
        onClose={handleCloseUpdateDealTimingModal}
        onConfirm={handleUpdateDealTiming}
        formErrors={updateDealTimingFormErrors}
        setFormErrors={setUpdateDealTimingFormErrors}
        form={form}
        setForm={setForm}
        isTransfer={currentDeal?.tax_credit_structure === "TR"}
      />
      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealTimingLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealTimingLogConfiguration?.id}
        />
      </LogsWrapper>
    </>
  );
}
