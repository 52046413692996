import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import ProjectCostChart from "./chart";
import Logs from "../../../../components/logs";
import LogsButton from "../../../../components/logs-button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import ChartWrapper from "../../../../components/chart-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import ProjectCostDetailChart from "./detail-chart";
import ProjectCostFormModal from "../../../../components/project-cost-form-modal";
import ConditionalProtect from "../../../../components/conditional-protect";
import { useAPI } from "../../../../utils/hooks";
import { useLogs } from "../../../../utils/hooks/logs.hooks";
import { useDrawer } from "../../../../utils/hooks/drawer.hooks";
import { downloadProjectCostScheduleDetail } from "../../../../apis/project/cost";
import {
  cn,
  numberToUSD,
  formatCurrency,
  replacePagePathForPersonalAccount,
} from "../../../../utils/helpers";
import {
  PROJECT_COST_SCHEDULE_REPORT_TYPE,
  PROJECT_COST_TYPE,
} from "../../../../constants";
import {
  ITableColumn,
  IProjectCostSummaryResponse,
  IProjectCostForm,
  IProjectCostFormErrors,
  ILogsConfiguration,
  IReportTable,
} from "../../../../interfaces";

export const PROJECT_COST_TABLE_COLUMNS: ITableColumn[] = [
  { id: "category", label: "Category", minWidth: 80, align: "left" },
  { id: "cost", label: "Cost ($)", minWidth: 80, align: "left" },
  {
    id: "cost_per_capacity",
    label: "Cost ($/Wdc)",
    minWidth: 80,
    align: "left",
  },
  { id: "cost_percentage", label: "Cost (%)", minWidth: 80, align: "left" },
];

interface IProps {
  getProjectCostSummary: (
    projectUuid: string,
  ) => Promise<IProjectCostSummaryResponse>;
  updateProjectCosts: (
    form: { profit_percent: number },
    projectUuid: string,
    costId: number,
  ) => Promise<unknown>;
  getProjectCostsScheduleDetail: (
    projectUuid: string,
    cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
  ) => Promise<IReportTable>;
}

export default function ProjectCostView({
  getProjectCostSummary,
  updateProjectCosts,
  getProjectCostsScheduleDetail,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { projectUuid } = useParams();

  const { organization } = useOrganization();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [projectCostScheduleDetail, setProjectCostScheduleDetail] =
    React.useState<IReportTable>();
  const [projectCostSummary, setProjectCostSummary] =
    React.useState<IProjectCostSummaryResponse>();
  const [projectCostForm, setProjectCostForm] =
    React.useState<IProjectCostForm>({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });
  const [updateProjectCostModalOpen, setUpdateProjectCostModalOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getProjectCostSummaryData();
    getProjectCostScheduleDetailCallAPI(projectUuid, "cost").then((res) => {
      res && setProjectCostScheduleDetail(res);
    });
  }, [projectUuid]);

  const getProjectCostSummaryData = () => {
    getProjectCostSummaryCallAPI(projectUuid).then((response) => {
      response && setProjectCostSummary(response);
    });
  };

  const {
    callAPI: getProjectCostSummaryCallAPI,
    errored: getCostSummaryFailed,
    loading: loadingCostSummary,
  } = useAPI((projectUuid: string) => getProjectCostSummary(projectUuid), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectCostScheduleDetailCallAPI,
    errored: getCostScheduleDetailFailed,
    loading: loadingCostScheduleDetail,
  } = useAPI(
    (
      projectUuid: string,
      cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
    ) => getProjectCostsScheduleDetail(projectUuid, cost_type),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateProjecCostCallAPI,
    loading: loadingUpdateProjecCost,
    fieldErrors: updateProjecCostFormErrors,
    setFieldErrors: setUpdateProjecCostFormErrors,
  } = useAPI<unknown, IProjectCostFormErrors>(
    (form, projectUuid: string, costId: number) =>
      updateProjectCosts(form, projectUuid, costId),
  );

  const handleOpenUpdateCostModalOpen = () => {
    setUpdateProjectCostModalOpen(true);
    setProjectCostForm({
      fmv_step_up_input_type:
        projectCostSummary?.data.fmv_step_up_input_type || "",
      profit_percent:
        projectCostSummary?.data.fmv_step_up_percentage.toString() || "",
      fmv_step_up: projectCostSummary?.data.fmv_step_up_amount.toString() || "",
    });
  };
  const handleCloseUpdateCostModalOpen = () => {
    setUpdateProjectCostModalOpen(false);
  };

  const onEditProjectCost = async (form: IProjectCostForm) => {
    const res = await updateProjecCostCallAPI(
      form,
      projectUuid,
      projectCostSummary?.data.project_cost_id,
    );
    res && getProjectCostSummaryData();
    return res;
  };

  const downloadCostReport = async () => {
    setIsDownloading(true);
    await downloadProjectCostScheduleDetail(String(projectUuid), "cost").catch(
      () => null,
    );
    setIsDownloading(false);
  };

  const projectCostLogConfiguration: ILogsConfiguration = {
    id: Number(projectCostSummary?.data?.project_cost_id),
    type: "projectcost",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectCostLogConfiguration.type,
      projectCostLogConfiguration.id,
    );
  };

  const handleCostClick = (costType: keyof typeof PROJECT_COST_TYPE) => {
    let pathToUse =
      costType === "LC"
        ? `/project/${projectUuid}/cost-credit-support`
        : `/project/${projectUuid}/cost-inputs`;

    if (!organization) {
      pathToUse = replacePagePathForPersonalAccount(
        pathToUse,
        "project",
      ) as string;
    }

    navigate(pathToUse);
  };

  return (
    <>
      <ViewWrapper loading={loadingCostSummary} error={getCostSummaryFailed}>
        <>
          {projectCostSummary && (
            <Box>
              <Box className={styles.classes.header}>
                <Box className={cn("flex items-center my-2")}>
                  <LogsButton onClick={handleOnOpenLogs} />
                </Box>
              </Box>
              <Box className={styles.classes.content}>
                <Box className={cn("flex flex-col gap-4")}>
                  <TableContainer component={Paper}>
                    <Table classes={{ root: styles.classes.table }}>
                      <TableHead>
                        <TableRow>
                          {PROJECT_COST_TABLE_COLUMNS.map((col, idx) => (
                            <TableCell key={idx}>{col.label}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectCostSummary.data.cost_items.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => handleCostClick(row.cost_type)}
                                classes={{ root: styles.classes.link }}
                              >
                                {
                                  PROJECT_COST_TYPE[
                                    row.cost_type as keyof typeof PROJECT_COST_TYPE
                                  ]
                                }
                              </Link>
                            </TableCell>
                            <TableCell>
                              {formatCurrency(row.cost_in_dollars, 6)}
                            </TableCell>
                            <TableCell>
                              {formatCurrency(row.cost_in_dollars_per_watt, 2)}
                            </TableCell>
                            <TableCell>
                              {row.cost_percentage.toFixed(2) + "%"}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className={styles.classes.boldRow}>
                          <TableCell>Total Project Cost</TableCell>
                          <TableCell>
                            {numberToUSD.format(
                              projectCostSummary.data.total_cost,
                            )}
                          </TableCell>
                          <TableCell>
                            {formatCurrency(
                              projectCostSummary.data.total_cost_per_watt,
                              2,
                            )}
                          </TableCell>
                          <TableCell>{Number(100).toFixed(2) + "%"}</TableCell>
                          {/* assuming total project cost to be 100% */}
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            FMV Step Up Percent
                            <ConditionalProtect type="project">
                              <IconButton
                                onClick={handleOpenUpdateCostModalOpen}
                              >
                                <EditIcon
                                  classes={{
                                    root: cn("ml-1 text-secondary"),
                                  }}
                                  fontSize="small"
                                />
                              </IconButton>
                            </ConditionalProtect>
                          </TableCell>
                          <TableCell>
                            {projectCostSummary.data.fmv_step_up_percentage +
                              "%"}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            FMV Step Up Amount
                            <ConditionalProtect type="project">
                              <IconButton
                                onClick={handleOpenUpdateCostModalOpen}
                              >
                                <EditIcon
                                  classes={{
                                    root: cn("ml-1 text-secondary"),
                                  }}
                                  fontSize="small"
                                />
                              </IconButton>
                            </ConditionalProtect>
                          </TableCell>
                          <TableCell>
                            {numberToUSD.format(
                              projectCostSummary.data.fmv_step_up_amount,
                            )}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>FMV</TableCell>
                          <TableCell>
                            {numberToUSD.format(projectCostSummary.data.fmv)}
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className={styles.classes.chartContainer}>
                    {projectCostSummary && (
                      <ChartWrapper title="Cost Components" fontWeight="bold">
                        <ProjectCostChart data={projectCostSummary?.data} />
                      </ChartWrapper>
                    )}
                  </Box>
                  <ViewWrapper
                    loading={loadingCostScheduleDetail}
                    error={getCostScheduleDetailFailed}
                  >
                    {projectCostScheduleDetail && (
                      <ReportTableV2
                        data={projectCostScheduleDetail}
                        download={downloadCostReport}
                        title="Cost Schedule"
                      />
                    )}
                  </ViewWrapper>
                  <ViewWrapper
                    loading={loadingCostScheduleDetail}
                    error={getCostScheduleDetailFailed}
                  >
                    {projectCostScheduleDetail && (
                      <ChartWrapper title="">
                        <ProjectCostDetailChart
                          data={projectCostScheduleDetail}
                        />
                      </ChartWrapper>
                    )}
                  </ViewWrapper>
                </Box>
              </Box>
            </Box>
          )}
        </>
      </ViewWrapper>

      <ProjectCostFormModal
        form={projectCostForm}
        setForm={setProjectCostForm}
        headerLabel="Edit FMV Step Up"
        loading={loadingUpdateProjecCost}
        onClose={handleCloseUpdateCostModalOpen}
        onConfirm={onEditProjectCost}
        open={updateProjectCostModalOpen}
        setFormErrors={setUpdateProjecCostFormErrors}
        formErrors={updateProjecCostFormErrors}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectCostLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectCostLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
