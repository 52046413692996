import { makeStyles } from "tss-react/mui";

export default makeStyles<{ autoHeight?: boolean }>()(
  (theme, { autoHeight }) => ({
    paperRoot: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      maxHeight: autoHeight ? "auto" : 477,
      overflow: "auto",
    },
    headingContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    sectionContainer: {
      // margin: theme.spacing(1, 0, 2, 0),
    },
    sectionHeaing: {
      fontSize: 14,
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      alignSelf: "end",
      fontWeight: "bold",
    },
    fieldContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      minHeight: 50,
      padding: theme.spacing(1),
      "& > *:first-of-type": {
        maxWidth: "60%",
      },
      "& p": {
        fontSize: 14,
      },
    },
    fieldValueContainer: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    tooltip: {
      backgroundColor: theme.palette.grey[500],
    },
  }),
);
