import {
  ReportTerm,
  ReportType,
  ReportPerspective,
  IConstructionDebtLoanReport,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";
import DealOutputConstructionDebtCommonView from "../common-view";

interface IProps {
  getDealReporting: (
    dealUuid: string,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IConstructionDebtLoanReport>;
}

export default function DealOutputConstructionDebtConstructionLoanView({
  getDealReporting,
}: IProps): JSX.Element {
  return (
    <DealOutputConstructionDebtCommonView
      getDealReporting={getDealReporting}
      reportType="constructionloan"
    />
  );
}
