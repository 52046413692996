import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import { IProjectProformaResponse, ProformaReportType } from "../../interfaces";

export const getDealProforma = async (
  dealUuid: string,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealProforma.get, {
    dealUuid,
    periodicity,
    basis,
  });
  return await AxiosHelper.get<IProjectProformaResponse>(url);
};

export const downloadDealProforma = async (
  dealUuid: string,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  return await AxiosHelper.get<Blob>(`deal-proforma/${dealUuid}/download/`, {
    params: {
      periodicity,
      basis,
    },
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `deal-output-download-proforma-${dealUuid}-${periodicity}.xlsx`,
    );
  });
};
