import React from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import LogsButton from "../../../components/logs-button";
import ActionButton from "../../../components/action-button";
import ViewWrapper from "../../../components/view-wrapper";
import DetailsCard from "../../../components/details-card";
import TextAsLink from "../../../components/text-as-link";
import ProjectsTable from "../../../components/projects-table";
import UpdateDealFormModal from "../../../components/update-deal-form-modal";
import ProjectFormModal from "../../../components/project-form-modal";
import DealDuplicateModal from "../../../components/deal-duplicate-modal";
import LogsWrapper from "../../../components/logs-wrapper";
import ConditionalProtect from "../../../components/conditional-protect";
import Logs from "../../../components/logs";
import ChartWrapper from "../../../components/chart-wrapper";
import CheckboxInput from "../../../components/checkbox-input";
import Chart from "../../project/pro-forma/dashboard/chart";
import TimingChart, {
  ITimingChartProps,
} from "../../../components/timing-chart";
import { deleteDeal } from "../../../apis/deal/base";
import {
  cn,
  numberToUSD,
  replaceCaseIdAttachedDealPagePath,
  trimString,
} from "../../../utils/helpers";
import {
  setCurrentDealAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  useLogs,
  useAPI,
  useDrawer,
  useAppSelector,
} from "../../../utils/hooks";
import {
  DEAL_STAGES,
  DEAL_STRUCTURE_TYPE,
  PROJECTS_TABLE_COLUMNS,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  PROJECT_FORM_DEFAULT_STATE,
  UPDATE_DEAL_FORM_DEFAULT_STATE,
  DEAL_STATUS,
  FORM_TYPES,
  PROFORMA_CHART_DETAIL_CONFIG,
} from "../../../constants";
import {
  IProject,
  ITableSort,
  ISelectOption,
  IUpdateDealForm,
  IProjectForm,
  IDuplicateDealForm,
  IUpdateDealFormErrors,
  IProjectFormErrors,
  IDuplicateDealFormErrors,
  IGetProjectsParams,
  ServerPaginatedResponse,
  IDealTiming,
  ProformaReportType,
  IUpdateDealResponse,
  IDuplicateDealResponse,
  IAddUpdateProjectResponse,
  ILogsConfiguration,
  IReportTable,
  IProjectProformaResponse,
} from "../../../interfaces";

// We are adding capacity_dc column in the table for general deal page
const UPDATED_PROJECTS_TABLE_COLUMNS = _.cloneDeep(PROJECTS_TABLE_COLUMNS);
UPDATED_PROJECTS_TABLE_COLUMNS.splice(5, 0, {
  id: "capacity_dc",
  label: "Capacity (DC)",
  minWidth: 50,
  align: "left",
});

interface IProps {
  getProjects: (
    params: IGetProjectsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
  duplicateDeal: (
    dealUuid: string,
    form: IDuplicateDealForm,
  ) => Promise<IDuplicateDealResponse>;
  updateDeal: (
    dealUuid: string,
    form: IUpdateDealForm,
  ) => Promise<IUpdateDealResponse>;
  getDealTiming: (dealUuid: string) => Promise<IDealTiming[]>;
  getDealProforma: (
    dealUuid: string,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
}

export default function DealGeneralView({
  getProjects,
  addProject,
  duplicateDeal,
  updateDeal,
  getDealTiming,
  getDealProforma,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();

  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const dispatch = useDispatch();
  const { currentDeal: deal, currentDealStatus: dealStatus } = useAppSelector(
    (s) => s.deal,
  );

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  const [addDealModalOpen, setAddDealModalOpen] =
    React.useState<boolean>(false);
  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);
  const [duplicateDealModalOpen, setDuplicateAddDealModalOpen] =
    React.useState<boolean>(false);
  const [projectOptions, setProjectOptions] = React.useState<ISelectOption[]>(
    [],
  );
  const [dealDuplicateForm, setDuplicateDealForm] =
    React.useState<IDuplicateDealForm>({
      name: "",
    });
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [dealForm, setDealForm] = React.useState<IUpdateDealForm>(
    UPDATE_DEAL_FORM_DEFAULT_STATE,
  );
  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );
  const [dealTiming, setDealTiming] = React.useState<IDealTiming>();
  const [showOperationPeriod, setShowOperationPeriod] =
    React.useState<boolean>(true);

  const [dealProforma, setDealProforma] = React.useState<IReportTable | null>(
    null,
  );

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  React.useEffect(() => {
    getDealTimingCallAPI(dealIdToUse).then((response) => {
      if (response) {
        setDealTiming(response[0]);
      }
    });
    getDealProformaDetailsHTCallAPI(String(dealIdToUse)).then((response) => {
      response?.data && setDealProforma(response.data);
    });
  }, [dealIdToUse]);

  React.useEffect(() => {
    if ((!dealStatus.loading && deal) || dealStatus.errored) {
      setShowLoader(false);
    }
  }, [dealStatus]);

  const {
    callAPI: getDealProformaDetailsHTCallAPI,
    errored: getDealProformaFailed,
    loading: loadingDealProforma,
  } = useAPI((dealUuid: string) => getDealProforma(dealUuid, "AN", "cash"), {
    initialLoading: true,
  });

  const {
    callAPI: getDealTimingCallAPI,
    errored: getTimingFailed,
    loading: loadingTiming,
  } = useAPI((dealUuid: string) => getDealTiming(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const {
    callAPI: duplicateDealCallAPI,
    fieldErrors: duplicateDealFormErrors,
    setFieldErrors: setDuplicateDealFormErrors,
    loading: duplicateDealLoading,
  } = useAPI<IDuplicateDealResponse, IDuplicateDealFormErrors>(
    (dealUuid: string, form: IDuplicateDealForm) =>
      duplicateDeal(dealUuid, form),
  );

  const {
    callAPI: updateDealCallAPI,
    fieldErrors: updateDealFormErrors,
    setFieldErrors: setUpdateDealFormErrors,
    loading: updateDealLoading,
  } = useAPI<IUpdateDealResponse, IUpdateDealFormErrors>(
    (dealUuid: string, form: IUpdateDealForm) => updateDeal(dealUuid, form),
  );

  const { callAPI: deleteDealCallAPI } = useAPI(() =>
    deleteDeal(String(dealUuid)),
  );

  const handleOpenDuplicateDealModal = async () => {
    setDuplicateAddDealModalOpen(true);
  };

  const handleCloseUpdateDealModal = () => {
    setAddDealModalOpen(false);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const handleCloseDuplicateDealModal = async () => {
    setDuplicateAddDealModalOpen(false);
  };

  const handleOpenAddProjectModal = () => {
    setAddProjectModalOpen(true);
  };

  const handleGetProjects = async () => {
    const projects = await getProjects({}).catch(() => null);
    if (projects) {
      setProjectOptions(
        projects.results.map((p) => ({
          label: p.name,
          value: String(p.uuid),
          status: p.status,
        })),
      );
    }
  };

  const handleOpenUpdateDealModal = async () => {
    await handleGetProjects();
    const { name, stage, tax_credit_structure, ...restDeal } = deal!;

    const projectUuids = restDeal.projects.map((p) => p.uuid);
    setDealForm({
      name,
      stage,
      tax_credit_structure,
      project_uuids: projectUuids,
      status: deal?.status as keyof typeof DEAL_STATUS,
    });
    setAddDealModalOpen(true);
  };

  const handleDuplicateDeal = async (form: IDuplicateDealForm) => {
    const deal = await duplicateDealCallAPI(caseDealUuid, form);

    if (deal) {
      navigate(`/deal/${deal.uuid}/case-deal/${deal.uuid}/general`);
      toast.success("Deal duplicated successfully!", {
        toastId: "deal-duplication-success",
      });
    }

    return deal;
  };

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);

    if (project) {
      setDealForm((prev) => ({
        ...prev,
        project_uuids: [...prev.project_uuids, String(project.uuid)],
      }));
      setProjectOptions((prev) => [
        ...prev,
        {
          label: project.name,
          value: String(project.uuid),
          status: project.status,
        },
      ]);
    }
    return project;
  };

  const handleUpdateDeal = async (form: IUpdateDealForm) => {
    const updatedDeal = await updateDealCallAPI(caseDealUuid, form);

    if (deal && updatedDeal) {
      const { name, stage, tax_credit_structure, projects, status } =
        updatedDeal;

      dispatch(
        setCurrentDealAction({
          ...deal,
          name,
          tax_credit_structure,
          stage,
          projects,
          status,
        }),
      );
      handleCloseUpdateDealModal();
    }

    return updatedDeal;
  };

  const dealTimingChartData: ITimingChartProps | undefined =
    React.useMemo(() => {
      if (dealTiming?.project_timings) {
        const chartData: ITimingChartProps["data"] = [];
        const startDates: number[] = [];

        dealTiming?.project_timings.forEach((projectTiming) => {
          const {
            project_name,
            horizon,
            cod,
            start_date,
            end_date,
            ntp_date,
            mechanical_completion_date,
            placed_in_service_date,
            substantial_completion_date,
          } = projectTiming;

          const dataPoints = [
            // {
            //   date: new Date(start_date),
            //   color: "#FFA700",
            //   symbol: "rect",
            //   tooltip: `${project_name} Start Date`,
            //   id: "start",
            // },
            {
              date: new Date(ntp_date),
              color: "#F44336",
              symbol: "rectRot",
              tooltip: `${project_name} | Notice to Proceed`,
              id: "ntp",
            },
            {
              date: new Date(mechanical_completion_date),
              color: "#00B7C3",
              symbol: "triangle",
              tooltip: `${project_name} | Mechanical Completion Date`,
              rotation: 180,
              id: "mc",
            },
            {
              date: new Date(substantial_completion_date),
              color: "#007EC5",
              symbol: "triangle",
              tooltip: `${project_name} | Substantial Completion Date`,
              id: "sc",
            },
            {
              date: new Date(placed_in_service_date),
              color: "#AB47BC",
              symbol: "circle",
              tooltip: `${project_name} | Placed In Service Date`,
              id: "pis",
            },
            {
              date: new Date(cod),
              color: "#00B96A",
              symbol: "rect",
              tooltip: `${project_name} | COD`,
              id: "cod",
            },
            ...(showOperationPeriod
              ? [
                  {
                    date: new Date(end_date),
                    color: "#F44336",
                    symbol: "circle",
                    tooltip: `${project_name} | End Date`,
                    id: "end",
                  },
                ]
              : []),
          ];

          startDates.push(new Date(start_date).getTime());

          chartData.push(
            ...dataPoints.filter((d) =>
              horizon === "OS" ? d.id === "cod" || d.id === "end" : true,
            ),
          );
        });

        const minDate = new Date(Math.min(...startDates));

        return {
          startDate: minDate,
          endDate: new Date(),
          data: chartData,
        };
      }

      return undefined;
    }, [dealTiming, showOperationPeriod]);

  const handleShowOperationPeriodToggle = () => {
    setShowOperationPeriod((s) => !s);
  };

  const navigateActionButton = (url: string) => {
    let urlToUse = url;

    if (!organization) {
      urlToUse = replaceCaseIdAttachedDealPagePath(urlToUse);
    }

    return (
      <IconButton onClick={() => navigate(urlToUse)}>
        <ArrowCircleRightIcon />
      </IconButton>
    );
  };

  const dealLogConfiguration: ILogsConfiguration = {
    id: String(caseDealUuid),
    type: "deal",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(dealLogConfiguration.type, dealLogConfiguration.id);
  };

  const handleDeleteDealConfirm = async () => {
    const response = await deleteDealCallAPI(String(dealUuid));
    if (response) {
      toast.success("Deal deleted successfully!", {
        toastId: "deal-deletion-success",
      });
      navigate("/deal-list");
    }
  };

  const handleDeleteProject = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal",
        description: "Are you sure you want to delete this Deal?",
        onConfirm: handleDeleteDealConfirm,
      }),
    );
  };

  return (
    <>
      <ViewWrapper loading={showLoader} error={dealStatus.errored}>
        <Box>
          <Box className={cn("flex justify-end gap-4 mb-4")}>
            <LogsButton onClick={handleOnOpenLogs} />

            {!isOnCase && (
              <>
                <ConditionalProtect type="deal">
                  <ActionButton
                    canOpenUpgrade
                    actionType="duplicate"
                    disabled={dealStatus.loading || dealStatus.errored}
                    onClick={handleOpenDuplicateDealModal}
                  >
                    Duplicate
                  </ActionButton>

                  <ActionButton
                    canOpenUpgrade
                    actionType="edit"
                    disabled={dealStatus.loading || dealStatus.errored}
                    onClick={handleOpenUpdateDealModal}
                  >
                    Edit
                  </ActionButton>
                </ConditionalProtect>
                {deal?.status === "ARCH" && (
                  <ConditionalProtect type="data_delete">
                    <ActionButton
                      onClick={handleDeleteProject}
                      actionType={"delete"}
                    >
                      Delete Deal
                    </ActionButton>
                  </ConditionalProtect>
                )}
              </>
            )}
          </Box>
        </Box>

        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6}>
            <DetailsCard
              autoHeight
              heading="Summary"
              sections={[
                {
                  fields: [
                    {
                      label: "Deal Name",
                      value: {
                        text: trimString(deal?.name, 40) || "",
                        tooltip: deal?.name,
                      },
                    },
                    {
                      label: "Deal Stage",
                      value: {
                        text: DEAL_STAGES[
                          deal?.stage as keyof typeof DEAL_STAGES
                        ],
                      },
                    },
                    {
                      label: "Deal Structure",
                      value: {
                        text: DEAL_STRUCTURE_TYPE[
                          deal?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                        ],
                      },
                    },
                    {
                      label: "Deal Tax Credit Structure",
                      value: {
                        text: DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                          deal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                        ],
                      },
                    },
                  ],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DetailsCard
              autoHeight
              heading="Other Key Information"
              sections={[
                {
                  fields: [
                    {
                      label: "Deal Start Date",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/deal/${dealUuid}/case-deal/${caseDealUuid}/timing`}
                            text={deal?.start_date || ""}
                          />
                        ),
                      },
                    },
                    {
                      label: "Operating Life",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/deal/${dealUuid}/case-deal/${caseDealUuid}/timing`}
                            text={deal?.operating_life_years + " Yrs" || ""}
                          />
                        ),
                      },
                    },
                    {
                      label: "Deal Term",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/deal/${dealUuid}/case-deal/${caseDealUuid}/timing`}
                            text={deal?.term + " Yrs" || ""}
                          />
                        ),
                      },
                    },
                    {
                      label: "Deal End Date",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/deal/${dealUuid}/case-deal/${caseDealUuid}/timing`}
                            text={deal?.end_date || ""}
                          />
                        ),
                      },
                    },
                    {
                      label: "FMV",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/deal/${dealUuid}/case-deal/${caseDealUuid}/component/cost`}
                            text={numberToUSD.format(deal?.fmv || 0)}
                          />
                        ),
                      },
                    },
                    {
                      label: "Capacity (AC)",
                      value: {
                        text: (
                          <TextAsLink
                            path={`/deal/${dealUuid}/case-deal/${caseDealUuid}/component/pro-forma`}
                            text={deal?.capacity_ac + " MW" || ""}
                          />
                        ),
                      },
                    },
                  ],
                },
              ]}
            />
          </Grid>
        </Grid>

        <ViewWrapper loading={loadingTiming} error={getTimingFailed}>
          {dealTimingChartData && (
            <ChartWrapper
              title="Timing"
              primaryButton={navigateActionButton(
                `/deal/${dealUuid}/case-deal/${caseDealUuid}/timing`,
              )}
            >
              <Box>
                <CheckboxInput
                  checked={showOperationPeriod}
                  label="Show Operations Period"
                  onChange={handleShowOperationPeriodToggle}
                />
                <TimingChart {...dealTimingChartData} />
              </Box>
            </ChartWrapper>
          )}
        </ViewWrapper>

        <ViewWrapper
          loading={loadingDealProforma}
          error={getDealProformaFailed}
        >
          {dealProforma ? (
            <Chart
              chartData={dealProforma}
              showOnlySummary={true}
              chartElementConfig={PROFORMA_CHART_DETAIL_CONFIG}
              primaryButton={navigateActionButton(
                `/deal/${dealUuid}/case-deal/${caseDealUuid}/component/pro-forma`,
              )}
              selectedBasis="cash"
            />
          ) : null}
        </ViewWrapper>

        {!isOnCase || !organization ? (
          <Box id="deal-projects">
            <Typography variant="h6">Projects in this Deal</Typography>
            <ProjectsTable
              columns={UPDATED_PROJECTS_TABLE_COLUMNS}
              sortTable={sortTable}
              setSortTable={setSortTable}
              rows={deal?.projects || []}
              openProjectInNewTab
              showCapacityDc
            />
          </Box>
        ) : null}
      </ViewWrapper>

      <UpdateDealFormModal
        form={dealForm}
        setForm={setDealForm}
        loading={updateDealLoading}
        existingProjects={projectOptions}
        formErrors={updateDealFormErrors}
        setFormErrors={setUpdateDealFormErrors}
        open={addDealModalOpen}
        onUpdate={handleUpdateDeal}
        onClose={handleCloseUpdateDealModal}
        openAddProjectModal={handleOpenAddProjectModal}
      />

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
        formType={FORM_TYPES.CREATE}
      />

      <DealDuplicateModal
        open={duplicateDealModalOpen}
        loading={duplicateDealLoading}
        onClose={handleCloseDuplicateDealModal}
        formErrors={duplicateDealFormErrors}
        setFormErrors={setDuplicateDealFormErrors}
        form={dealDuplicateForm}
        setForm={setDuplicateDealForm}
        headerLabel="Duplicate Deal"
        onConfirm={handleDuplicateDeal}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
