import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import SelectInput from "../select-input";
import { PROJECT_TIMING_PERIODICITY_OPTIONS } from "../../constants";
import { IProjectModelForm, SetStateAction } from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IProjectModelForm;
  setForm: SetStateAction<IProjectModelForm>;
  onClose: () => void;
  onConfirm: (form: IProjectModelForm) => Promise<void>;
}

export default function DownloadModelFormModal({
  headerLabel,
  open,
  loading,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnConfirm = async () => {
    await onConfirm(form);
    handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({ periodicity: "" });
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <SelectInput
          label="Pro Forma Periodicity"
          selected={form.periodicity}
          items={PROJECT_TIMING_PERIODICITY_OPTIONS}
          onChange={(e) => handleSelectInputChange(e, "periodicity")}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
