import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import {
  PROJECT_API_ROUTES,
  PROJECT_COST_SCHEDULE_REPORT_TYPE,
} from "../../constants";
import {
  IAddProjectCreditSupportForm,
  ICostItemForm,
  ICostItemResponse,
  IProjectCost,
  IProjectCostDataResponse,
  IProjectCostItemDataResponse,
  IProjectCostSummaryResponse,
  IProjectCreditSupport,
  IReportTable,
  IUpdateProjectCostForm,
  IUpdateProjectCreditSupportForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const getProjectCostsSummary = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.summary.get, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectCostSummaryResponse>(url);
};

export const updateProjectCosts = async (
  form: { profit_percent: number },
  projectUuid: string,
  costId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costUpdate, {
    projectUuid,
    costId,
  });
  return await AxiosHelper.put(url, form);
};

export const getProjectCostList = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costList, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectCostDataResponse>(url);
};

export const getProjectCostItem = async (
  projectUuid: string,
  costId: number,
  itemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.get, {
    projectUuid,
    costId,
    itemId,
  });
  return await AxiosHelper.get<IProjectCostItemDataResponse>(url);
};

export const createProjectCostItem = async (
  form: ICostItemForm,
  projectUuid: string,
  costId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.create, {
    projectUuid,
    costId,
  });
  return await AxiosHelper.post<ICostItemForm, ICostItemResponse>(url, form);
};

export const editProjectCostItem = async (
  form: ICostItemForm,
  projectUuid: string,
  costId: number,
  itemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.update, {
    projectUuid,
    costId,
    itemId,
  });
  return await AxiosHelper.put<ICostItemForm, ICostItemResponse>(url, form);
};

export const deleteProjectCostItem = async (
  projectUuid: string,
  costId: number,
  itemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.delete, {
    projectUuid,
    costId,
    itemId,
  });
  return await AxiosHelper.delete(url);
};

export const updateProjectCost = async (
  projectUuid: string,
  costId: number,
  form: IUpdateProjectCostForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.update, {
    projectUuid,
    costId,
  });
  return await AxiosHelper.patch<IUpdateProjectCostForm, IProjectCost>(
    url,
    form,
  );
};

export const getProjectCreditSupport = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.get, {
    projectUuid,
  });
  return await AxiosHelper.get<
    ServerPaginatedResponse<IProjectCreditSupport[]>
  >(url);
};

export const getDetailsCreditSupport = async (
  projectUuid: string,
  creditSupportId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.getDetails, {
    projectUuid,
    creditSupportId,
  });
  return await AxiosHelper.get<IProjectCreditSupport>(url);
};

export const addProjectCreditSupport = async (
  projectUuid: string,
  form: IAddProjectCreditSupportForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.create, {
    projectUuid,
  });
  return await AxiosHelper.post<
    IAddProjectCreditSupportForm,
    IProjectCreditSupport
  >(url, form);
};

export const editProjectCreditSupport = async (
  projectUuid: string,
  creditSupportId: number,
  form: IUpdateProjectCreditSupportForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.update, {
    projectUuid,
    creditSupportId,
  });
  return await AxiosHelper.put<
    IUpdateProjectCreditSupportForm,
    IProjectCreditSupport
  >(url, form);
};

export const deleteProjectCreditSupport = async (
  projectUuid: string,
  creditSupportId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.delete, {
    projectUuid,
    creditSupportId,
  });
  return await AxiosHelper.delete(url);
};

export const getProjectCostsScheduleDetail = async (
  projectUuid: string,
  cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.cost.summary.getCostScheduleDetail,
    {
      projectUuid,
      cost_type,
    },
  );
  return await AxiosHelper.get<IReportTable>(url);
};

export const getProjectCostItemComponents = async (
  projectUuid: string,
  projectCostId: number,
  projectCostItemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.getComponents, {
    projectUuid,
    projectCostId,
    projectCostItemId,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectCostItemComponents = async (
  projectUuid: string,
  projectCostId: number,
  projectCostItemId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.cost.costItem.downloadComponents,
    {
      projectUuid,
      projectCostId,
      projectCostItemId,
    },
  );
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-${projectUuid}-cost-item-components-report.xlsx`,
    );
  });
};

export const getProjectCreditSupportDetailSchedule = async (
  projectUuid: string,
  projectCreditSupportItemId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.cost.creditSupport.getCreditSupportDetailSchedule,
    {
      projectUuid,
      projectCreditSupportItemId,
    },
  );
  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadProjectCostScheduleDetail = async (
  projectUuid: string,
  cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.cost.summary.downloadCostScheduleDetail,
    {
      projectUuid,
      cost_type,
    },
  );
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-${cost_type}-report-download-${projectUuid}.xlsx`,
    );
  });
};
