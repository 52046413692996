import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import { IProjectEBITDA, IProjectEBITDA_Form } from "../../interfaces";

export const getProjectScheduleOverrides = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.scheduleOverrides.get, {
    projectUuid,
  });
  return await AxiosHelper.get<IProjectEBITDA[]>(url);
};

export const updateProjectScheduleOverride = async (
  projectUuid: string,
  overrideId: number,
  form: IProjectEBITDA_Form,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.scheduleOverrides.update, {
    projectUuid,
    overrideId,
  });
  return await AxiosHelper.patch<IProjectEBITDA_Form, IProjectEBITDA>(
    url,
    form,
  );
};
