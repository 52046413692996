import React from "react";
import Box from "@mui/material/Box";
import { isInteger, some } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import ContentWrappedTabs from "../content-wrapped-tabs";
import { getLayoutTabs } from "../../utils/helpers";
import { PRIMARY_SIDEBAR_NAVIGATION } from "../../constants";
import { ITab } from "../../interfaces";
import { useAppSelector } from "../../utils/hooks";
import { useOrganization } from "@clerk/clerk-react";

interface IProps {
  children: JSX.Element;
  module: "Projects" | "Deals" | "Configuration" | "Collaboration";
  pages?:
    | "Pro Forma"
    | "Tax"
    | "Components"
    | "Sizing"
    | "Output"
    | "Cost"
    | "Valuation"
    | "Cases";
  withPadding?: boolean;
  hasStickyTabs?: boolean;
}

export default function TabbedLayout({
  children,
  module,
  pages,
  withPadding,
  hasStickyTabs = false,
}: IProps): JSX.Element {
  const { organization } = useOrganization();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectUuid, dealUuid, caseDealUuid, collaborationUuid } =
    useParams();

  const { valuationExist: projectValuationExist } = useAppSelector(
    (s) => s.project,
  );
  const { currentDeal, currentDealTermDebt, casesOfDeal } = useAppSelector(
    (s) => s.deal,
  );
  const { ctrlPressed, contentLoaded } = useAppSelector((s) => s.common);

  const styles = useStyles({ contentLoaded, withPadding });

  const tabs: ITab[] = React.useMemo(() => {
    const links = !pages
      ? PRIMARY_SIDEBAR_NAVIGATION.find((i) => i.label === module)?.subLinks ||
        []
      : PRIMARY_SIDEBAR_NAVIGATION.find(
          (i) => i.label === module,
        )?.subLinks?.find((i) => i.label === pages)?.subLinks || [];

    return getLayoutTabs({
      module,
      currentDeal,
      currentDealTermDebt,
      projectValuationExist,
      links,
    });
  }, [
    module,
    pages,
    caseDealUuid,
    casesOfDeal,
    currentDeal,
    currentDealTermDebt,
    projectValuationExist,
  ]);

  // TODO: comeup with a better way to get the selected tab
  const selectedTab = React.useMemo(() => {
    // url path
    const splitPath = pathname.split("/");

    // For organization pages
    if (pathname.includes("/organization/")) {
      return "organization";
    }
    if (pathname.includes("/data/")) {
      return "data";
    }

    // For deal output pages
    if (pathname.includes("/output/")) {
      // Get the tab value e.g sponsor-equity / tax-equity ...
      // splitPath[6] gives the current tab value from the url
      const outputTabIndex = !organization ? 5 : 6;
      const t = splitPath[outputTabIndex];
      if (some(tabs, { value: t })) {
        return t;
      } else {
        const firstTab = tabs[0];
        // Replace placeholder with actual value
        const firstTabUrl =
          firstTab?.path
            ?.replace(":dealUuid", String(dealUuid))
            ?.replace(":caseDealUuid", String(caseDealUuid)) || "";
        // Navigate to the first tab
        navigate(firstTabUrl);
        return firstTab.value;
      }
    }

    // For expense detail page, or revenue detail page, or otherflows detail page
    if (isInteger(Number(splitPath[splitPath.length - 1]))) {
      // when splitPath[splitPath.length - 1] is the id (number)
      return (
        tabs.find(
          (tab) =>
            // Ignore pro-forma because it's included in every expense, revenue detail's URL
            tab.value !== "pro-forma" && pathname.includes(`/${tab.value}/`),
        )?.value || ""
      );
    }

    // For revenue pages
    if (pathname.includes("/merchant") || pathname.includes("ebitda")) {
      return "revenue";
    }

    // for project cost pages
    if (pathname.includes(`/project/${projectUuid}/cost`)) {
      if (pathname.includes("cost-inputs")) {
        return "cost-inputs";
      } else if (pathname.includes("credit-support")) {
        return "cost-credit-support";
      } else {
        return "cost-summary";
      }
    }

    // for project valuation pages
    if (pathname.includes(`/project/${projectUuid}/valuation`)) {
      if (pathname.includes("calculation")) {
        return "valuation-calculation";
      } else {
        return "valuation-summary";
      }
    }

    // select the last element of splitPath as tab value
    return splitPath[splitPath.length - 1];
  }, [tabs, dealUuid, caseDealUuid, pathname]);

  const goToPage = (tab: ITab) => {
    let link = !organization ? `/personal${tab.path || ""}` : tab.path || "";
    if (!organization && link.includes("/case-deal/")) {
      link = link.replace("/case-deal/:caseDealUuid", "");
    }
    // If the tab is already selected then no need to go further just return
    if (selectedTab === tab.value) {
      return;
    }

    // Replace placeholders with actual values
    link = link
      .replace(":projectUuid", String(projectUuid))
      .replace(":dealUuid", String(dealUuid))
      .replace(":caseDealUuid", String(caseDealUuid))
      .replace(":collaborationUuid", String(collaborationUuid));

    // If Ctrl key is pressed, open in new tab
    if (ctrlPressed) {
      window.open(link);
      return;
    }
    // Navigate to the updated link
    navigate(link);
  };

  return (
    <Box className={styles.classes.container}>
      <ContentWrappedTabs
        onClick={goToPage}
        tabs={tabs}
        selectedTab={selectedTab}
        hasStickyTabs={hasStickyTabs}
      >
        {children}
      </ContentWrappedTabs>
    </Box>
  );
}
