import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealFee,
  IDealFeeForm,
  ServerPaginatedResponse,
  IDealOnlyCost,
} from "../../interfaces";

export const getDealFees = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.fee.get, { dealUuid });
  return await AxiosHelper.get<ServerPaginatedResponse<IDealFee[]>>(url);
};

export const getDealFeeDetails = async (dealUuid: string, feeId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.fee.getFeeDetails, {
    dealUuid,
    feeId,
  });
  return await AxiosHelper.get<IDealFee>(url);
};

export const addDealFee = async (dealUuid: string, form: IDealFeeForm) => {
  const url = replaceParams(DEAL_API_ROUTES.fee.create, { dealUuid });
  return await AxiosHelper.post<IDealFeeForm, IDealFee>(url, form);
};

export const updateDealFee = async (
  dealUuid: string,
  feeId: number,
  form: IDealFeeForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.fee.update, {
    dealUuid,
    feeId,
  });
  return await AxiosHelper.patch<IDealFeeForm, IDealFee>(url, form);
};

export const deleteDealFee = async (dealUuid: string, feeId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.fee.delete, {
    dealUuid,
    feeId,
  });
  return await AxiosHelper.delete(url);
};

export const getDealOnlyCost = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.deal_cost, { dealUuid });
  return await AxiosHelper.get<IDealOnlyCost>(url);
};
