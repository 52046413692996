import { enumToOptions } from "../utils/helpers";

export enum SOLVER_FOR_TYPES {
  CPRICE = "Contract Price ($)",
}

export enum SOLVER_GOAL_TYPES {
  SE_BPT = "Sponsor Equity BOL Pre-Tax IRR",
  SE_BAT = "Sponsor Equity BOL After-Tax IRR",
  SE_CPT = "Sponsor Equity COD Pre-Tax IRR",
  SE_CAT = "Sponsor Equity COD After-Tax IRR",
}

export enum SOLVER_STATUS {
  CREATED = "Created",
  RUNNING = "Running",
  ERROR = "Error",
  FINISHED = "Finished",
}

export const SOLVER_FOR_OPTIONS = enumToOptions(SOLVER_FOR_TYPES);
export const SOLVER_GOAL_OPTIONS = enumToOptions(SOLVER_GOAL_TYPES);
export const SOLVER_STATUS_OPTIONS = enumToOptions(SOLVER_STATUS);
