import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../components/view-wrapper";
import ProjectCosts from "./project-costs";
import CostItemFormModal from "../../../../components/cost-item-form-modal";
import { useAPI } from "../../../../utils/hooks";
import { getProjectTimingDateSchedule } from "../../../../apis/project/timing";
import { COST_ITEM_FORM_DEFAULT_STATE } from "../../../../constants";
import {
  ICostItemForm,
  ICostItemFormErrors,
  ICostItemResponse,
  IProjectCostDataResponse,
  IProjectTimingDateScheduleResults,
} from "../../../../interfaces";

interface IProps {
  createProjectCostItem: (
    form: ICostItemForm,
    projectUuid: string,
    projectCostId: number,
  ) => Promise<ICostItemResponse>;
  getProjectCostList: (
    projectUuid: string,
  ) => Promise<IProjectCostDataResponse>;
  editProjectCostItem: (
    form: ICostItemForm,
    projectUuid: string,
    projectCostId: number,
    itemId: number,
  ) => Promise<ICostItemResponse>;
}

export default function ProjectCostInputsView({
  getProjectCostList,
  createProjectCostItem,
}: IProps): JSX.Element {
  const { projectUuid } = useParams();
  const [projecCostData, setProjectCostData] =
    React.useState<IProjectCostDataResponse>();
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [openAddCostModal, setOpenAddCostModal] = React.useState(false);
  const [addCostForm, setAddCostForm] = React.useState<ICostItemForm>(
    COST_ITEM_FORM_DEFAULT_STATE,
  );

  const {
    callAPI: getProjectCostListCallAPI,
    loading: loadingProjectCostList,
    errored: projectCostListFailed,
  } = useAPI((projectUuid) => getProjectCostList(projectUuid), {
    initialLoading: true,
  });

  React.useEffect(() => {
    if (projectUuid) {
      getProjectCosts(projectUuid);
      getCostDateSchedule(projectUuid);
    }
  }, [projectUuid]);

  const getProjectCosts = async (projectUuid: string) => {
    getProjectCostListCallAPI(projectUuid).then((res) => {
      res && setProjectCostData(res);
    });
  };

  const projectCostId: string | undefined = React.useMemo(() => {
    return projecCostData?.data.project_cost.id.toString();
  }, [projecCostData]);

  const updateCosts = () => {
    getProjectCosts(String(projectUuid));
  };

  const handleOpenAddCostModal = async () => {
    setAddCostForm((prev) => ({
      ...prev,
      cost_percentage_curve: new Array(dateSchedule.length ?? 0).fill(0),
    }));
    setOpenAddCostModal(true);
  };

  const handleCloseAddCostModal = () => {
    setOpenAddCostModal(false);
  };

  const getCostDateSchedule = async (projectUuid: string) => {
    getProjectTimingDateScheduleCallAPI(projectUuid).then((res) => {
      res && setDateSchedule(res.data.date_schedule);
    });
  };

  const { callAPI: getProjectTimingDateScheduleCallAPI } = useAPI<
    IProjectTimingDateScheduleResults,
    ICostItemFormErrors
  >((projectId) =>
    getProjectTimingDateSchedule({ period_type: "PRE_COD" }, projectId),
  );

  const {
    callAPI: createCostItemCallAPI,
    loading: createCostItemLoading,
    fieldErrors: createCostItemFormErrors,
    setFieldErrors: setCreateCostItemFormErrors,
  } = useAPI<ICostItemResponse, ICostItemFormErrors>(
    (form, projectId, costId) => createProjectCostItem(form, projectId, costId),
  );

  const onAddConfirm = async (form: ICostItemForm) => {
    const res = await createCostItemCallAPI(
      form,
      projectUuid,
      projecCostData?.data.project_cost.id,
    );
    res && getProjectCosts(String(projectUuid));
    return res;
  };

  return (
    <ViewWrapper loading={loadingProjectCostList} error={projectCostListFailed}>
      <ProjectCosts
        projectUuid={projectUuid!}
        projectCostId={projectCostId!}
        costItems={projecCostData?.data.cost_items || []}
        viewType="single"
        handleUpdate={updateCosts}
        handleOpenAddCostModal={handleOpenAddCostModal}
      />

      <CostItemFormModal
        dateSchedule={dateSchedule}
        form={addCostForm}
        headerLabel="Add Project Cost"
        loading={createCostItemLoading}
        onClose={handleCloseAddCostModal}
        onConfirm={onAddConfirm}
        open={openAddCostModal}
        setForm={setAddCostForm}
        setFormErrors={setCreateCostItemFormErrors}
        formErrors={createCostItemFormErrors}
      />
    </ViewWrapper>
  );
}
