import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IUpdateProjectTimingForm,
  IProjectTiming,
  IProjectTimingDateScheduleParams,
  IProjectTimingDateScheduleResults,
  IReportTable,
  ProjectTimingPeriodType,
} from "../../interfaces";

export const getProjectTiming = async (projectUuid: string) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.get, { projectUuid });
  return await AxiosHelper.get<IProjectTiming[]>(url);
};

export const editProjectTiming = async (
  projectUuid: string,
  timingId: number,
  form: IUpdateProjectTimingForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.update, {
    projectUuid,
    timingId,
  });
  return await AxiosHelper.patch<IUpdateProjectTimingForm, IProjectTiming>(
    url,
    form,
  );
};

export const getProjectTimingDateSchedule = async (
  params: IProjectTimingDateScheduleParams,
  projectUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.dateSchedule, {
    projectUuid,
  });
  return await AxiosHelper.post<
    IProjectTimingDateScheduleParams,
    IProjectTimingDateScheduleResults
  >(url, params);
};

export const getProjectTimingDateScheduleTable = async (
  periodType: ProjectTimingPeriodType,
  projectUuid: string,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.dateScheduleTable, {
    projectUuid,
    periodType,
  });
  return await AxiosHelper.get<IReportTable>(url);
};
