import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import { isNumber } from "lodash";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import LogsButton from "../../../../components/logs-button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ConditionalProtect from "../../../../components/conditional-protect";
import DealPartnershipInvestorConfigurationFormModal from "../../../../components/deal-tax-equity-form-modal";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import DetailsCard, {
  IDetailsCardSection,
} from "../../../../components/details-card";
import { useAPI, useLogs, useDrawer } from "../../../../utils/hooks";
import { DEAL_TAX_EQUITY_FORM_DEFAULT_STATE } from "../../../../constants";
import {
  cn,
  filterDealTaxEquityData,
  getFilterableDealTaxEquityData,
} from "../../../../utils/helpers";
import {
  IDealTiming,
  IDealTaxEquity,
  IDealTaxEquityForm,
  IDealTaxEquityFormErrors,
  ILogsConfiguration,
} from "../../../../interfaces";

interface IProps {
  getDealTaxEquity: (dealUuid: string) => Promise<IDealTaxEquity[]>;
  getDealTiming: (dealUuid: string) => Promise<IDealTiming[]>;
  updateDealTaxEquity: (
    dealUuid: string,
    taxEquityId: number,
    form: IDealTaxEquityForm,
  ) => Promise<IDealTaxEquity>;
  dealStructureType: "tax-equity" | "cash-equity";
}

export default function DealInputTaxEquityView({
  getDealTaxEquity,
  getDealTiming,
  updateDealTaxEquity,
  dealStructureType,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealUuid, caseDealUuid } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealUuid : dealUuid;

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [dealTiming, setDealTiming] = React.useState<IDealTiming>();
  const [dealTaxEquity, setDealTaxEquity] = React.useState<IDealTaxEquity>();
  const [filteredTaxEquity, setFilteredTaxEquity] = React.useState<
    IDetailsCardSection[]
  >([]);
  const [editTaxEquityModalOpen, setEditTaxEquityModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IDealTaxEquityForm>(
    DEAL_TAX_EQUITY_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getDealTaxEquityCallAPI(dealIdToUse).then((response) => {
      if (response && response[0]) {
        const TE = response[0];
        setDealTaxEquity(TE);
        const filterableTE = getFilterableDealTaxEquityData(
          TE,
          dealStructureType,
        );
        const filteredTE = filterDealTaxEquityData(
          filterableTE,
          TE.type,
          TE.solve_for,
        );
        setFilteredTaxEquity(filteredTE);
      }
    });
    getDealTimingCallAPI(dealIdToUse).then((response) => {
      response && setDealTiming(response[0]);
    });
  }, [dealIdToUse]);

  const isOnCase = React.useMemo(() => {
    return dealUuid !== caseDealUuid;
  }, [dealUuid, caseDealUuid]);

  const { callAPI: getDealTimingCallAPI } = useAPI((dealUuid: string) =>
    getDealTiming(dealUuid),
  );

  const {
    callAPI: getDealTaxEquityCallAPI,
    errored: getTaxEquityFailed,
    loading: loadingTaxEquity,
  } = useAPI((dealUuid: string) => getDealTaxEquity(dealUuid), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealTaxEquityCallAPI,
    fieldErrors: updateDealTaxEquityFormErrors,
    setFieldErrors: setUpdateDealTaxEquityFormErrors,
    loading: updateDealTaxEquityLoading,
  } = useAPI<IDealTaxEquity, IDealTaxEquityFormErrors>(
    (dealUuid: string, taxEquityId: number, form: IDealTaxEquityForm) =>
      updateDealTaxEquity(dealUuid, taxEquityId, form),
  );

  const handleOnEditDealTaxEquity = async (form: IDealTaxEquityForm) => {
    const taxEquity = await updateDealTaxEquityCallAPI(
      caseDealUuid,
      Number(dealTaxEquity?.id),
      form,
    );
    if (taxEquity) {
      setDealTaxEquity(taxEquity);
      const FilterableTE = getFilterableDealTaxEquityData(
        taxEquity,
        dealStructureType,
      );
      const filteredTE = filterDealTaxEquityData(
        FilterableTE,
        taxEquity.type,
        taxEquity.solve_for,
      );
      setFilteredTaxEquity(filteredTE);
    }

    return taxEquity;
  };

  const setEquityFundingDate = async () => {
    if (dealTiming) {
      setForm((prevState) => {
        return {
          ...prevState,
          tax_equity_funding_date: dealTiming.tax_equity_date_1,
        };
      });
    }
  };

  const handleOpenEditDealPartnershipInvestorConfigurationModal = async () => {
    if (dealTaxEquity) {
      const {
        id,
        created,
        modified,
        deal,
        new_federal_tax_rate,
        federal_tax_rate_change_year,
        tax_step_tax_split,
        federal_tax_rate,
        ...formDetails
      } = dealTaxEquity;
      setForm({
        ...formDetails,
        tax_equity_input_method: formDetails.tax_equity_input_method ?? "",
        change_in_federal_tax_rate: isNumber(
          new_federal_tax_rate || federal_tax_rate_change_year,
        )
          ? "Y"
          : "N",
        new_federal_tax_rate: new_federal_tax_rate || "",
        tax_step_tax_split: tax_step_tax_split || "",
        federal_tax_rate: federal_tax_rate || "",
        federal_tax_rate_change_year,
      });
      await setEquityFundingDate();
      setEditTaxEquityModalOpen(true);
    }
  };

  const handleCloseEditDealPartnershipInvestorConfigurationModal = () => {
    setEditTaxEquityModalOpen(false);
  };

  const equityType =
    dealStructureType === "cash-equity" ? "Cash Equity" : "Tax Equity";

  const dealSizingEquityLogConfiguration: ILogsConfiguration = {
    id: Number(dealTaxEquity?.id),
    type: "partnershipinvestorconfiguration",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealSizingEquityLogConfiguration.type,
      dealSizingEquityLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingTaxEquity} error={getTaxEquityFailed}>
        <Box>
          {!dealTaxEquity && (
            <ConditionalProtect type="deal">
              <Box className={styles.classes.emptyContainer}>
                <Box>
                  <Typography
                    variant="body1"
                    className={styles.classes.createInfo}
                  >
                    {`${equityType} on this deal does not exist. Please click Add ${equityType} to add one.`}
                  </Typography>
                </Box>
              </Box>
            </ConditionalProtect>
          )}

          {dealTaxEquity && (
            <Box>
              <Box className={cn("flex justify-end items-center gap-2 mb-4")}>
                <ToggleSizingOutputButton />
                <LogsButton onClick={handleOnOpenLogs} />

                {!isOnCase && (
                  <ConditionalProtect type="deal">
                    <Button
                      canOpenUpgrade
                      startIcon={<EditIcon />}
                      label="Edit"
                      btnType="primary"
                      onClick={
                        handleOpenEditDealPartnershipInvestorConfigurationModal
                      }
                    />
                  </ConditionalProtect>
                )}
              </Box>
              <Box>
                <Box
                  className={cn(
                    "flex flex-col md:flex-row *:flex-1 *:flex *:flex-col *:gap-4 gap-4",
                  )}
                >
                  {filteredTaxEquity.length > 0 && (
                    <Box>
                      {filteredTaxEquity.slice(0, 2).map((tE, idx) => {
                        return (
                          <DetailsCard
                            key={idx}
                            heading={tE.heading || ""}
                            sections={[{ fields: tE.fields }]}
                            autoHeight
                          />
                        );
                      })}
                    </Box>
                  )}
                  {filteredTaxEquity.length > 2 && (
                    <Box>
                      {filteredTaxEquity.slice(2, 4).map((tE, idx) => {
                        return (
                          <DetailsCard
                            key={idx}
                            heading={tE.heading || ""}
                            sections={[{ fields: tE.fields }]}
                          />
                        );
                      })}
                    </Box>
                  )}
                  {filteredTaxEquity.length > 4 && (
                    <Box>
                      {filteredTaxEquity.slice(4, 6).map((tE, idx) => {
                        return (
                          <DetailsCard
                            key={idx}
                            heading={tE.heading || ""}
                            sections={[{ fields: tE.fields }]}
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <DealPartnershipInvestorConfigurationFormModal
        open={editTaxEquityModalOpen}
        onClose={handleCloseEditDealPartnershipInvestorConfigurationModal}
        form={form}
        loading={updateDealTaxEquityLoading}
        formErrors={updateDealTaxEquityFormErrors}
        setFormErrors={setUpdateDealTaxEquityFormErrors}
        setForm={setForm}
        headerLabel={`Edit ${equityType}`}
        onConfirm={handleOnEditDealTaxEquity}
      />
      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealSizingEquityLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealSizingEquityLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
