import Box from "@mui/material/Box";
import React from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";

import { useAPI } from "../../utils/hooks";
import { cn } from "../../utils/helpers";
import { getFilledChipColor } from "../../utils/helpers/solvers.helpers";
import { setCurrentCollaborationAction } from "../../utils/redux/slices";
import { getCollaborationDetails } from "../../apis/collaboration/base";
import { ISolver } from "../../interfaces/deal/solvers.interface";
import Layout from "../layout";
import { SOLVER_STATUS } from "../../constants/solver";
import Chip from "../general/Chip";

interface IProps {
  currentSolver: ISolver | null;
  children: JSX.Element;
}

export default function SolverDetailsLayout({
  currentSolver,
  children,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { organization } = useOrganization();

  const { collaborationUuid } = useParams();

  const { callAPI: getCollaborationDetailsCallAPI } = useAPI(
    getCollaborationDetails,
  );

  React.useEffect(() => {
    collaborationUuid && fetchCollaborationDetails(collaborationUuid);
  }, [collaborationUuid]);

  const fetchCollaborationDetails = (uuId: string) => {
    getCollaborationDetailsCallAPI(uuId).then((res) => {
      dispatch(setCurrentCollaborationAction(res));
    });
  };

  return (
    <>
      <Layout title={currentSolver?.name || "Solver Details"}>
        <>
          <Box className={cn("!mx-4 mt-4")}>
            {currentSolver && (
              <Box>
                {organization ? (
                  <Link to="/solvers">
                    <Typography className={cn("hover:!text-secondary")}>
                      <ArrowBackIcon /> Back to Deal Solvers List
                    </Typography>
                  </Link>
                ) : null}

                <Box className="flex items-start justify-start flex-col mt-6">
                  <Box className="flex items-center justify-start gap-4">
                    <Typography variant="h5" component="h5" marginBottom={1}>
                      {currentSolver.name}
                    </Typography>
                    <Chip
                      label={
                        SOLVER_STATUS[
                          currentSolver.status as keyof typeof SOLVER_STATUS
                        ]
                      }
                      color={
                        getFilledChipColor(currentSolver.status).color as string
                      }
                      filledBackgroundColor={
                        getFilledChipColor(currentSolver.status).backgroundColor
                      }
                      variant="filled"
                      className="-mt-2"
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    component="small"
                    marginBottom={1}
                  >
                    Created by {currentSolver.created_by}
                  </Typography>
                </Box>
                <Divider classes={{ root: cn("!mt-4") }} />
              </Box>
            )}
          </Box>
          <Box className={cn("!mx-4 mt-4")}>{children}</Box>
        </>
      </Layout>
    </>
  );
}
