import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealCreditSupportSummary,
  IDealOnlyCost,
  IDealProjectCostComponent,
  IReportTable,
} from "../../interfaces";

export const getDealCost = async (
  dealUuid: string,
  cost_input_type: string,
) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.get, {
    dealUuid,
    cost_input_type,
  });
  return await AxiosHelper.get<IDealProjectCostComponent>(url);
};

export const getCreditSupportSummary = async (
  dealUuid: string,
  cost_input_type: string,
) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.getCreditSupportSummart, {
    dealUuid,
    cost_input_type,
  });
  return await AxiosHelper.get<IDealCreditSupportSummary>(url);
};

export const getDealOnlyCost = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.deal_cost, { dealUuid });
  return await AxiosHelper.get<IDealOnlyCost>(url);
};

export const getDealCostsSchedule = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.summary.getCostSchedule, {
    dealUuid,
  });
  return await AxiosHelper.get<IReportTable>(url);
};
