import { DEAL_API_ROUTES } from "../../constants";
import { IDealOtherSizing, IUpdateDealOtherSizingForm } from "../../interfaces";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getDealOtherSizing = async (dealUuid: string) => {
  const url = replaceParams(DEAL_API_ROUTES.dealOtherSizing.get, {
    dealUuid,
  });
  return await AxiosHelper.get<IDealOtherSizing[]>(url);
};

export const updateDealOtherSizing = async (
  dealUuid: string,
  sizingId: number,
  form: IUpdateDealOtherSizingForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealOtherSizing.update, {
    dealUuid,
    sizingId,
  });
  return await AxiosHelper.patch<IUpdateDealOtherSizingForm, IDealOtherSizing>(
    url,
    form,
  );
};
